/*
 * @feature: 配置项
 * @author: liuhua.chen
 * @Date: 2024-11-05 08:47:17
 */
const folderColors = ['#ffffff', '#D3E1FF', '#D0F1EB', '#DED2F9', '#F5D4C1', '#FDC9D2', '#F9E8BD', '#D9F2B9', '#BEF5F5', '#FEB9B9', '#F9BDEF']

const tranlateLanguage = [
  {
    lang: 'zh',
    label: '中文简体',
    model: '16k_zh' // 腾讯云，实时识别的engine_model_type
  },
  {
    lang: 'zh_tw',
    label: '中文繁体',
    model: '16k_zh-TW'
  },
  {
    lang: 'en',
    label: '英语',
    model: '16k_en'
  },
  {
    lang: 'japanese',
    label: '日语',
    model: '16k_ja'
  },
  {
    lang: 'german',
    label: '德语',
    model: '16k_de'
  },
  {
    lang: 'french',
    label: '法语',
    model: '16k_fr'
  },
  {
    lang: 'korean',
    label: '韩语',
    model: '16k_ko'
  },
  {
    lang: 'russian',
    label: '俄语',
    model: '16k_zh',
    type: 'ali'
  },
  {
    lang: 'panish',
    label: '西班牙语',
    model: '16k_es'
  },
  {
    lang: 'italian',
    label: '意大利语',
    model: '16k_zh',
    type: 'ali'
  },
  {
    lang: 'cantonese',
    label: '粤语',
    model: '16k_yue'
  },
  {
    lang: 'thai',
    label: '泰语',
    model: '16k_th'
  },
  {
    lang: 'indonesian',
    label: '印尼语',
    model: '16k_id'
  },
  {
    lang: 'malay',
    label: '马来西亚语',
    model: '16k_ms'
  },
  {
    lang: 'filipino',
    label: '菲律宾语',
    model: '16k_fil'
  },
  {
    lang: 'arabic',
    label: '阿拉伯语',
    model: '16k_ar'
  }
]

export {
  folderColors,
  tranlateLanguage
}
