<!--
 * @feature: 转写设置
 * @author: liuhua.chen
 * @Date: 2024-11-25 15:29:53
-->
<template>
  <ConfirmModal v-model="model" width="300" :title="$t('home.reSetting')" :ok-loading="loading" @on-cancel="onCancel" @on-ok="onSubmit">
    <div class="tran-wrap">
      <span class="text">{{ $t('home.reLang') }}</span>
      <Select v-model="lang" transfer class="origin-select">
        <Option v-for="item in tranlateLanguage" :value="item.lang" :key="item.lang">{{ item.label }}</Option>
      </Select>
    </div>
  </ConfirmModal>
</template>

<script setup>
import { tranlateLanguage } from '@/libs/config'
import { ref, defineModel, defineEmits, getCurrentInstance } from 'vue'
import ConfirmModal from '@/components/common/confirm-modal.vue'

const _this = getCurrentInstance().appContext.config.globalProperties
// 弹窗是否显示
const model = defineModel()

const emit = defineEmits(['update:modelValue'])

const loading = ref(false)

const lang = ref(tranlateLanguage[0].lang)

function onSubmit () {
  _this.$Message.info('功能待开发')
}

function onCancel () {
  emit('update:modelValue', false)
}

</script>

<style scoped lang='less'>
.tran-wrap {
  display: flex;
  flex-direction: column;
  padding: 10px 0 15px 0;
}
.text {
  margin-bottom: 10px;
}
</style>
