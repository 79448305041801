/*
 * @feature: 用户信息
 * @author: liuhua.chen
 * @Date: 2024-11-04 11:15:11
 */
const file = {
  state: {
    colorList: {}
  },
  mutations: {
    setColorList (state, list) {
      state.colorList = list
    }
  },
  actions: { 
    setColorList ({ commit }, list) {
      commit('setColorList', list)
    }
  }
}

export default file
