
/* eslint-disable no-async-promise-executor */
const indexDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB
class IndexDBCache {
  // 构造函数
  constructor () {
    this._db = null // 数据库
    this._transaction = null // 事务
    this._request = null
    this._dbName = 'aivox' // 数据库名
    this._cacheTableName = 'record' // 表名
    this._dbversion = 1 // 数据库版本
    this._keyPath = 'blob'
  }

  initDB (val) {
    return new Promise((resolve, reject) => {
      if (this._db) {
        resolve(this._db)
      } else {
        this._request = indexDB.open(this._dbName, this._dbversion) // 打开数据库
        // 数据库初始化成功
        this._request.onsuccess = (event) => {
          this._db = this._request.result
          resolve(this._db)
        }
        // 数据库初始化失败
        this._request.onerror = (event) => {
          reject(event)
        }
        // 数据库初次创建或更新时会触发
        this._request.onupgradeneeded = (event) => {
          const db = this._request.result
          if (!db.objectStoreNames.contains(this._cacheTableName)) {
            db.createObjectStore(this._cacheTableName, {
              keyPath: this._keyPath // 设置主键
            })
          }
        }
      }
    })
  }

  closeDB () {
    if (this._db) {
      this._db.close()
      console.log('关闭数据库')
    }
  }

  addData (params) {
    return new Promise(async (resolve, reject) => {
      const store = await this.getStore(this._cacheTableName)
      params[this._keyPath] = 'blob'
      const response = store.put(params)
      // 操作成功
      response.onsuccess = (event) => {
        console.log('操作成功')
        resolve(event)
      }
      // 操作失败
      response.onerror = (event) => {
        console.log('操作失败111111111111111111:', event)
        reject(event)
      }
    })
  }

  // 通过主键读取数据
  getDataByKey (key, table) {
    key = key || this._keyPath
    table = table || this._cacheTableName

    return new Promise(async (resolve, reject) => {
      const objectStore = await this.getStore(table)
      // 通过主键读取数据
      const request = objectStore.get(key)
      // 操作成功
      request.onsuccess = () => {
        console.log(' request.onsuccess', request)
        resolve(request.result)
      }
      // 操作失败
      request.onerror = (event) => {
        reject(event)
      }
    })
  }

  async getStore (table) {
    const db = await this.initDB()
    const _transaction = this._transaction || db.transaction([table], 'readwrite')
    return _transaction.objectStore(table)
  }

  // 读取所有数据
  readAll (table) {
    table = table || this._cacheTableName
    return new Promise(async (resolve) => {
      const objectStore = await this.getStore(table)
      const request = objectStore.openCursor()

      const data = []

      request.onsuccess = function (event) {
        const cursor = event.target.result

        if (cursor && cursor != null) {
          data.push(cursor.value)
          cursor.continue()
        } else {
          resolve(data)
        }
      }
    })
  }

  // 根据key，删除数据
  remove (key) {
    return new Promise(async (resolve, reject) => {
      const objectStore = await this.getStore(this._cacheTableName)
      const request = objectStore.delete(key)

      request.onsuccess = function (event) {
        console.log('数据删除成功')
        resolve(event)
      }

      request.onerror = function (event) {
        console.log('数据删除失败')
        reject(event)
      }
    })
  }

  // 清空数据库数据
  clearDB () {
    return new Promise(async (resolve, reject) => {
      const store = await this.getStore(this._cacheTableName)
      const response = store && store.clear()
      // 操作成功
      response.onsuccss = (event) => {
        console.log('清空数据库数据')
        resolve(event)
      }
      // 操作失败
      response.onerror = (event) => {
        reject(event)
      }
    })
  }

  deleteDBAll (dbName) {
    console.log(dbName)
    const deleteRequest = indexDB.deleteDatabase(this._dbName)
    return new Promise((resolve, reject) => {
      deleteRequest.onerror = function (event) {
        console.log('删除失败')
      }
      deleteRequest.onsuccess = function (event) {
        console.log('删除数据库成功')
      }
    })
  }
}
export default IndexDBCache
