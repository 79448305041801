import axios from 'axios'
import store from '@/store'
import errorCode from '@/utils/errorCode'
import Lockr from 'lockr'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 30000
})
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === true
  if (!isToken) {
    const token = Lockr.get('authorization') || '123456'
    config.headers['easy-token'] = token // 让每个请求携带自定义token 请根据实际情况自行修改
  }

  // 是否需要指定的token
  const configToken = (config.headers || {}).token
  if (configToken) {
    config.headers['easy-token'] = configToken
  }

  config.headers['user-channel'] = 'C'

  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?'
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName]
      const part = encodeURIComponent(propName) + '='
      if (value !== null && typeof (value) !== 'undefined') {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            const params = propName + '[' + key + ']'
            const subPart = encodeURIComponent(params) + '='
            url += subPart + encodeURIComponent(value[key]) + '&'
          }
        } else {
          url += part + encodeURIComponent(value) + '&'
        }
      }
    }
    url = url.slice(0, -1)
    config.params = {}
    config.url = url
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => res, interceptErr)

export default async function (options) {
  const [err, res] = await service(options).then(reponse => [null, reponse]).catch(err => [err])
  // 非200
  if (err) return Promise.reject(new Error(err))

  // 200
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200
  // 获取错误信息
  const msg = errorCode[code] || res.data.detail || res.data.msg || errorCode.default
  if (code === 401) {
    window.$Message.destroy()
    window.$Message.warning(res.data.msg)
    store.commit('logout')
    return Promise.reject(code)
  } else if (code === 500) {
    window.$Message.destroy()
    window.$Message.error(msg)
    return Promise.reject(new Error(msg))
  } else if (code === 400) {
    window.$Message.destroy()
    window.$Message.error(msg)
    return Promise.reject(new Error('error'))
  } else if (code === 404) {
    return Promise.reject(new Error('error'))
  } else if (code !== 200) {
    window.$Message.destroy()
    window.$Message.error(msg)
    return Promise.reject(new Error('error'))
  } else {
    return res.data
  }
}

function interceptErr (error) {
  console.log('err' + error)
  let { message } = error
  if (message === 'Network Error') {
    message = '网络连接异常，请稍后重试'
  } else if (message.includes('timeout')) {
    message = '网络连接异常，请稍后重试'
  } else if (message.includes('Request failed with status code')) {
    message = '网络连接异常，请稍后重试'
  }
  if (message !== store.state.settings?.errorNetwork) {
    store.dispatch('settings/noticeErrorNetwork', message)
    // Message({
    //   message: message,
    //   type: 'warning',
    //   duration: 5 * 1000,
    //   onClose: () => {
    //     store.dispatch('settings/noticeErrorNetwork', 'durationing')
    //   }
    // })
  }
  return Promise.reject(error)
}
