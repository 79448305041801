/*
 * @feature:
 * @author: liuhua.chen
 * @Date: 2024-11-12 15:57:45
 */
import { createStore } from 'vuex'
import Lockr from 'lockr'
import user from './modules/user'
import file from './modules/file'

export default createStore({
  state: {
    globalLoading: false,
    lang: ''
  },
  mutations: {
    showLoading (state, status) {
      state.globalLoading = status
    },
    logout (state, vm) {
      window.router.replace('/login')
    },
    setLang (state, status) {
      state.lang = status
      Lockr.set('lang', status)
    }
  },
  actions: {
    showLoading ({ commit }, status) {
      commit('showLoading', status)
    },
    logout ({ commit }) {
      commit('logout')
    },
    setLang ({ commit }, status) {
      commit('setLang', status)
    }
  },
  modules: {
    user,
    file
  }
})
