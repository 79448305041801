/*
 * @feature: 
 * @author: liuhua.chen
 * @Date: 2024-11-01 15:16:31
 */
module.exports = {
  appName: '飞利浦声文',
  common: {
    loading: '加载中...',
    success: '操作成功',
    sure: '确定',
    cancel: '取消',
    refresh: '刷新',
    delete: '删除',
    copy: '复制',
    edit: '编辑'
  },
  navigator: {
    home: '首页',
    recycle: '回收站',
    pay: '充值购买',
    cooperation: '商务合作',
    message: '消息通知',
    useTime: '可用时长',
    callTime: '通话时长 {0}',
    dateLine: '{0} 到期',
    record: '录音列表',
    allFolder: '所有文件',
    starFolder: '星标文件'
  },
  login: {
    title: '用户登录',
    smsTitle: '验证码登录',
    codeTitle: '密码登录',
    phoneHint: '请输入您的手机号码',
    codeHint: '请输入验证码',
    codebtn: '获取验证码',
    second: '秒',
    privacyTitle: '登录您的帐户即表示您同意',
    userPrivacy: '《用户协议》',
    and: '和',
    privacy: '《隐私政策》',
    login: '登录',
    phoneError: '请输入正确的手机号码',
    checkPrivacy: '请勾选协议',
    sendSuccess: '发送成功',
    nologinId: '请输入账号',
    nopassword: '请输入密码'
  },
  file: {
    dialog: {
      creatTitle: '新建文件夹',
      renameTitle: '重命名',
      inputHint: '请输入文件夹名称',
      subtitle: '文件夹名称',
      deleteTitle: '删除 {name} 文件夹',
      deleteContent: '确定删除「{name}」？音频文件将移至「全部文件」'
    },
    batchMove: '批量移入',
    batchStar: '批量收藏',
    batchUnstar: '批量解除收藏',
    batchDelete: '批量删除',
    selectAll: '全选',
    deleteContent: '该文件将移入云回收站，15天后自动删除',
    deleteFile: '删除文件',
    moveFile: '移入文件夹',
    refreshList: '刷新列表',
    sureMove: '确认移动',
    mulSelect: '多选',
    showTranslate: '显示翻译',
    exportOri: '导出原文',
    exportMusic: '导出音频',
    editInfo: '修改信息',
    batchAddAbstact: '批量添加摘要',
    addAbstract: '添加摘要',
    addPic: '添加图片',
    abstract: '摘要',
    abstractTime: '摘要时长',
    translate: '翻译语言',
    deleteFileContent: '是否删除 {name} 文件',
    submit: '提交',
    reset: '重置',
    meetingname: '会议名称',
    meetingPersonel: '与会人',
    meetingAdress: '会议地点',
    editPlaceHolder: '请输入',
    required: '必填',
    hasAbstract: '已经添加到摘要',
    copySuccess: '复制成功',
    deleteAbstractContent: '删除这段摘要内容',
    save: '保存',
    saveContent: '保存 (Ctrl / ⌘ + Enter)',
    share: {
      audioTitle: '分享音频链接',
      digestTitle: '分享摘要链接',
      top1: '分享链接及提取码已生成',
      top2: '通过QQ、微信、微博等分享给好友吧',
      link: '链接',
      code: '提取码',
      codeBtn: '复制链接及提取码',
      qrHint: '微信扫一扫，进行分享',
      encrypt: '加密',
      yes: '是',
      no: '否',
      once: '阅后即焚',
      dateTime: '有效期',
      day: '{day}天',
      isSave: '是否保存',
      save: '允许保存',
      nosave: '不可保存',
      getLinkBtn: '生成链接自动复制',
      copyContent: '{username}分享的录音文件：{title} \n链接: {url}\n提取码: {checkCode}',
      aiTitle: 'AI摘要',
      finish: '完成',
      aiHint: 'AI生成，仅供参考'
    },
    upload: {
      formatError: '文件格式有误',
      error: '上传失败',
      downError: '文件下载失败',
      export: '导出中...'
    },
    shareDetail: {
      subTitle: '简单、纯粹、高效的AI云笔记软件',
      visite: '访问官网',
      download: '立即下载',
      shareText: '输入分享码，访问分享内容',
      codeBtn: '点击输入',
      save: '保存到飞利浦声文',
      codeHint: '请输入分享码',
      accountHint: '输入您的手机号或邮箱',
      smsCodeHint: '请输入验证码',
      saveHint: '保存到小鹿声文即表示您已同意',
      accountError: '账号格式错误',
      success: '转存成功',
      successHint: '文件已保存到「同步文件」',
      go: '请在飞利浦声文 App 中查看'
    }
  },
  home: {
    openHint: '立享 10+ 会员权益，低至 0.5 元/时',
    open: '去开通',
    recentRecord: '最近录音',
    upload: '上传录音',
    audioWrite: '声文速记',
    audioTrans: '同声传译',
    reWrite: '重新转写',
    reSetting: '转写设置',
    reLang: '转写语言',
    editInfo: '编辑基础信息',
    uploadFile: '上传录音文件',
    uploadHint: '单击或拖动文件到此区域进行上传',
    next: '下一步',
    preview: '上一步',
    privacy1: '我已阅读且同意',
    privacy2: '并对我上传文件的合法合规性负责',
    recogiz: '识别语言',
    selectFileHint: '请选择音频',
    privacyHint: '请先阅读并勾选同意《用户协议》和《隐私政策》',
    selectLang: '选择语言',
    syncBtn: '待同步音频',
    syncHint: '您有 {count} 条同步失败的记录，点击继续同步',
    syncModalTitle: '{count}文件等待同步',
    sync: '同步',
    download: '下载'
  },
  tranlateLang: {
    zh: '中文（简体）',
    zh_tw: '中文（繁体）',
    en: '英语',
    japanese: '日语',
    german: '德语',
    french: '法语',
    korean: '韩语',
    russian: '俄语',
    panish: '西班牙语',
    italian: '意大利语',
    cantonese: '粤语',
    thai: '泰语',
    indonesian: '印尼语',
    malay: '马来西亚语',
    filipino: '菲律宾语',
    arabic: '阿拉伯语'
  },
  live: {
    back: '返回',
    resume: '恢复录音',
    pause: '暂停录音',
    stop: '结束录音',
    record: '录音',
    notautorecord: '界面未有任何交互，请手动点击开始录音',
    networkLow: '网络信息弱',
    neterr: '网络异常',
    short: '录音时间太短',
    saveErr: '保存失败',
    socketErr: '语音识别异常，录音持续中',
    socketErrTitle: '语音识别异常',
    socketErrContent: '当前实时语音识别异常，您可在录音结束后查看识别内容'
  }
}
