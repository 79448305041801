<template>
  <CustomDraw v-model="model" :title="$t('home.selectLang')" :mask-closable="false" @on-ok="doOk">
    <div class="body-wrap">
      <!-- 同声翻译按钮 -->
      <div v-if="isSimul()" class="top-wrap">
        <Button class="btn" :class="{'current': currentBtn === 'from'}" @click="onlanguagechange('from')">{{ $t('tranlateLang.' + fromLang.lang ) }}</Button>
        <Icon type="ios-arrow-round-forward" size="30"/>
        <Button class="btn" :class="{'current': currentBtn === 'to'}" @click="onlanguagechange('to')">{{ $t('tranlateLang.' + toLang.lang ) }}</Button>
      </div>

      <div class="content-wrap">
        <div v-for="(item, index) in tranlateLanguage" :key="index" class="item-wrap" :class="{'selected': selectLang === item.lang, 'disable': getItemDisable(item) }" @click="onItemClick(item, index)">
          <div class="text">{{ $t('tranlateLang.' + item.lang ) }}</div>
          <Icon v-if="selectLang === item.lang" type="md-checkmark" size="20"/>
        </div>
      </div>
    </div>
  </CustomDraw>
</template>

<script setup>
import { ref, defineModel, defineProps, computed, watch } from 'vue'
import CustomDraw from '@/components/common/custom-draw.vue'
import { tranlateLanguage } from '@/libs/config'
import { useRouter } from 'vue-router'

const model = defineModel()

const router = useRouter()

const prop = defineProps({
  type: String // 值为simul为同声传译
})

// 当前设置的语言类型，值 from ,to
const currentBtn = ref('from')

// 当前选中的语言
const selectLang = computed(() => {
  return currentBtn.value === 'from' ? fromLang.value.lang : toLang.value.lang
})

// 源语言
const fromLang = ref(tranlateLanguage[0])

// 要翻译的语言
const toLang = ref(tranlateLanguage[2])

function onItemClick (item, index) {
  // 非同声翻译
  if (!isSimul()) {
    fromLang.value = tranlateLanguage[index]
    return
  }

  const itemDisable = getItemDisable(item)
  if (itemDisable) return

  // 当前在选择源语言
  if (currentBtn.value === 'from') {
    fromLang.value = tranlateLanguage[index]

    // 如果选择了中文，翻译语言要切换到第三个
    if ((index === 0 || index === 1) && (toLang.value.lang === tranlateLanguage[0].lang || toLang.value.lang === tranlateLanguage[1].lang)) {
      toLang.value = tranlateLanguage[2]

      // 如果翻译的语言跟源语言一样，切换到第一个语言
    } else if (toLang.value.lang === fromLang.value.lang) {
      toLang.value = tranlateLanguage[0]
    }

    // 选择完后，要切换到翻译语言
    currentBtn.value = 'to'
  } else {
    toLang.value = tranlateLanguage[index]
  }
}

// 源语言和翻译语言切换
function onlanguagechange (current) {
  currentBtn.value = current
  selectLang.value = current === 'from' ? fromLang.value.lang : toLang.value.lang
}

function getItemDisable (item) {
  // from不需要disable
  if (currentBtn.value === 'from') return false

  // 中文简体，繁体归同一种语言，from选择了中文简体或翻译，to两个语言都不能选择
  if (fromLang.value.lang === tranlateLanguage[0].lang || fromLang.value.lang === tranlateLanguage[1].lang) {
    return item.lang === tranlateLanguage[0].lang || item.lang === tranlateLanguage[1].lang
  }

  return item.lang === fromLang.value.lang
}
/**
 * @description: 是否是同声翻译
 * @author: liuhua.chen
 * @Date: 2024-11-26 14:13:30
 */
function isSimul () {
  return prop.type === 'simul'
}

function doOk () {
  let path = '/live?from=' + fromLang.value.lang

  if (isSimul()) path += '&to=' + toLang.value.lang
  router.push(path)
}

watch(() => model.value, (val) => {
  if (val) {
    currentBtn.value = 'from'
    fromLang.value = tranlateLanguage[0]
    toLang.value = tranlateLanguage[2]
  }
})

</script>

<style scoped lang='less'>
@import '~@/styles/index.less';
.body-wrap {
  padding: 10px 24px;
  width: 100%;
  background-color: rgb(240, 242, 245);
}
.content-wrap {
  background-color: white;
  border-radius: 8px;
}
.item-wrap {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .text  {
    font-size: 16px;
  }
}
.selected {
  color: @primary-color;
}
.top-wrap {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn {
    width: 150px;
    height: 40px;
    border-radius: 10px;
    font-weight: bold;
  }
  .current {
    background-color: #fffaea;
    color: #e2b42b;
    border: 1px solid #e2b42b;
  }
}
.disable {
  color: #e7e7e7;
}
</style>
