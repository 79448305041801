/*
 * @feature: 工具类
 * @author: liuhua.chen
 * @Date: 2024-11-01 09:41:38
 */
import Lockr from 'lockr'
import DownloadFileWorker from './worker/download-file'
import { i18n } from '@/lang'
/**
 * 取浏览器当前语言
 * @param {_} 分隔符
 */
export function getBrowserLang (_) {
  const lang = (Lockr.get('lang') || navigator.language || navigator.browserLanguage).replace(/[-_]/, _)
  const reg = new RegExp(`(\\w+)\\${_}(\\w+)`)
  const lo = lang.toLowerCase()

  function toLoUp (language) { // 将分隔符后面的字符大写
    return language.replace(reg, (all, $1, $2) => `${$1.toLowerCase()}${_}${$2.toUpperCase()}`) // 匹配到会被替换，匹配不到原样返回
  }
  function toI18nLang (language) {
    if (language === 'zh-tw') return 'zh-TW'
    if (language.startsWith('zh')) return 'zh-CN'
    if (language.startsWith('en')) return 'en'
    return 'en'
  }

  return {
    lang,
    apiLang: lo === 'en' ? 'en_US' : toLoUp(lang),
    i18nLang: toI18nLang(lo),
    lo,
    up: lang.toUpperCase(),
    lo_up: toLoUp(lang)
  }
}

/**
 * @description: 检查手机号码
 * @author: liuhua.chen
 * @Date: 2024-11-04 10:21:02
 */
export function checkIsPhone (phone) {
  return /^1(3|4|5|6|7|8|9)\d{9}$/.test(phone)
}

/**
 * 日期格式化函数
 * @param {Date} date - 日期
 * @param {String} fmt - 格式字符串,默认值 'yyyy-MM-dd hh:mm:ss' {'yyyy': '年份', 'MM': '月份', 'dd': '日期', 'hh': 小时, 'mm': '分钟', 'ss': '秒钟', 'qq': 季度, 'WW': 星期, 'S': '毫秒'}
 * @return {String} 格式化后的日期字符串
 * @example
 * util.getDateFormat(new Date(), 'yy年MM月dd日') // -> '20年03月06日'
 * util.getDateFormat(new Date(), 'yy年MM月dd日 h时m分s秒 第qq季度 W S毫秒') // -> '20年03月06日 11时41分23秒 第01季度 星期五 828毫秒'
 */
export function getDateFormat (date, fmt) {
  if (!fmt) {
    fmt = 'yyyy-MM-dd hh:mm:ss'
  }
  // var weekday = [i18n.t(`util.weekday.sunday`), i18n.t(`util.weekday.monday`), i18n.t(`util.weekday.tuesday`), i18n.t(`util.weekday.wednesday`), i18n.t(`util.weekday.thursday`), i18n.t(`util.weekday.friday`), i18n.t(`util.weekday.saturday`)]
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    // 'W+': weekday[date.getDay()], // 星期
    S: date.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(fmt) && k !== 'W+') {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  // 如果，周国际化后字母S也会被格式化，所以周单独拿出来最后替换
  if (/(W+)/.test(fmt)) {
    fmt = fmt.replace(/(W+)/, o['W+'])
  }
  return fmt
}

/**
 * 时间戳转时间字符串
 * @param {Number} timeStamp - 时间戳
 * @param {String} fmt - 格式字符串,默认值 'yyyy-MM-dd hh:mm:ss' {'yyyy': '年份', 'MM': '月份', 'dd': '日期', 'hh': 小时, 'mm': '分钟', 'ss': '秒钟', 'qq': 季度, 'WW': 星期, 'S': '毫秒'}
 * @return {String} 格式化后的日期字符串
 * @example
 * util.getTimeStamp2DateStr(1583473039) // -> '2020-03-06 13:37:19'
 */
export function getTimeStamp2DateStr (timeStamp, fmt) {
  if (!timeStamp) {
    return '1970-01-01 00:00:00'
  }
  timeStamp = parseInt(timeStamp)
  if (timeStamp.toString().length !== 10 && timeStamp.toString().length !== 13) {
    return '1970-01-01 00:00:00'
  }
  timeStamp = timeStamp.toString().length === 10 ? timeStamp * 1000 : timeStamp
  if (!fmt) {
    fmt = 'yyyy-MM-dd hh:mm:ss'
  }
  const newDate = new Date()
  newDate.setTime(timeStamp)
  return getDateFormat(newDate, fmt)
}
/**
 * 秒数转时分秒格式  如 10  ==> 00:00:10
 * @param s
 */
export function formatSecond (value) {
  let secondTime = parseInt(value)// 秒
  let minuteTime = 0// 分
  if (secondTime >= 60) {
    minuteTime = parseInt(secondTime / 60)
    secondTime = parseInt(secondTime % 60)
    if (minuteTime >= 60) {
      minuteTime = parseInt(minuteTime % 60)
    }
  }
  if (secondTime < 10) {
    secondTime = '0' + secondTime
  }
  if (minuteTime < 10) {
    minuteTime = '0' + minuteTime
  }
  return minuteTime + ':' + secondTime
}
/**
 * 获得后缀
 * @param {*} filename
 * @returns
 */
export function getSuffix (filename) {
  const pos = filename.lastIndexOf('.')
  let suffix = ''
  if (pos !== -1) {
    suffix = filename.substring(pos).toLocaleLowerCase()
  }
  return suffix
}

export function dimaTo (promise) { // write async without try catch
  return promise.then(data => [null, data]).catch(err => [err, {}])
}
/**
 * 删除URL指定参数
 * @param {String} url url地址
 * @param {String} parameter 参数key
 * @return {*} string
 * @author aaron.wei 2023.01.10
 */
export function removeURLParameter (url, parameter) {
  const urlparts = url.split('?')
  if (urlparts.length >= 2) {
    const prefix = encodeURIComponent(parameter) + '='
    const pars = urlparts[1].split(/[&;]/g)
    for (let i = pars.length; i-- > 0;) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1)
      }
    }
    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '')
  }
  return url
}
/**
 * 解析url,获取参数
 * @param {String} url url地址
 * @return {*} string
 * return { url :xxx, param1: xxxx, param2:xxx}
 */
export function getURLParameter (urlStr) {
  const url = urlStr || ''
  const theRequest = {}
  if (url.indexOf('?') !== -1) {
    const str = url.substr(url.indexOf('?') + 1)
    const strs = str.split('&')
    if (urlStr) theRequest.url = url.substr(0, url.indexOf('?'))
    for (let i = 0; i < strs.length; i++) {
      const item = strs[i].split('=')
      theRequest[item[0]] = decodeURIComponent(item[1])
    }
  }
  return theRequest
}
/**
 * 设置HTML title 内容
 * @param {String} title - 标题字符串
 */
export function setTitle (title) {
  title = title || '飞利浦声文'
  window.document.title = title
}
/**
 * @description: 验证是否是邮箱
 * @param {*} mail
 * @author: liuhua.chen
 * @Date: 2024-11-22 14:09:16
 */
export function checkMail (email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

/**
 * 获取登录平台
 */
export function getPlatform () {
  const pad = navigator.userAgent.match(/(pad|iPad)/i)
  const phone = navigator.userAgent.match(/(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  if (pad) {
    return 'PAD'
  }
  return phone ? 'PHONE' : 'WEB'
}
/**
 * @description: 下载文档
 * @param {*} downloadWorker
 * @param {*} callback
 * @param {*} url
 * @author: liuhua.chen
 * @Date: 2024-11-25 11:46:27
 */
export function downLoadFile ({ downloadWorker, name, callback, url }) {
  // 创建worker
  if (!downloadWorker) {
    downloadWorker = new DownloadFileWorker()

    // 监听子线程发送的消息
    downloadWorker.onMessage(e => {
      const { status, blob, filename } = e.data

      // 下载失败
      if (status !== 'ok') {
        callback?.error && callback.error()
        window.$Message.error(i18n.global.$t('file.upload.downError'))
        return
      }

      // 下载文件
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename
      a.click()
      window.URL.revokeObjectURL(url)

      callback && callback.finish()
    })
  }

  // 往子线程中发送任务
  downloadWorker.downloadFile({ url: url, filename: name })
  return downloadWorker
}
/**
 * @description: 获取带星标的手机号,如139*****12
 * @param {*} tel
 * @author: liuhua.chen
 * @Date: 2024-12-05 15:24:06
 */
export function geStarTel (tel) {
  const reg = /^(\d{3})\d{6}(\d{2})$/
  return tel.replace(reg, '$1****$2')
}
