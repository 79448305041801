<template>
  <Modal v-model="isShow" width="580" footer-hide :title="props.type === 'audio' ? $t('file.share.audioTitle') : $t('file.share.digestTitle')" :style="{padding: '0 0 24px'}">
    <!-- 分享成功 -->
    <div v-if="hasCode" class="result-wrap">
      <div class="top-wrap">
        <Icon type="ios-checkmark-circle-outline" color="#66cc5f" size="45" />
        <div class="text-wrap">
          <div class="top-text"> {{ $t('file.share.top1') }}</div>
          <div class="sub-text"> {{ $t('file.share.top2') }} </div>
        </div>
      </div>

      <!-- url链接 -->
      <div class="input-wrap">
        <div class="title">{{ $t('file.share.link') }}</div>
        <Input class="input" readonly v-model="shareResult.url"></Input>
        <Button class="link-btn" icon="ios-link" @click="doCopyLink"></Button>
      </div>

      <!-- 提取码 -->
      <div v-if="encrypt === 1" class="input-wrap">
        <div class="title">{{ $t('file.share.code') }}</div>
        <div class="password-input-wrap">
          <Input class="password-input" readonly v-model="shareResult.checkCode"></Input>
          <Button type="primary" @click="doCopyCode">{{ $t('file.share.codeBtn') }}</Button>
        </div>
      </div>

      <div class="qr-wrap">
        <vue-qr :text="shareResult.url" :size="140" />
       {{ $t('file.share.qrHint') }}
      </div>

      <Button class="cancel-btn" @click="() => isShow = false">{{ $t('common.cancel')}}</Button>
    </div>
    <!-- 还未获取分享码 -->
    <div v-else class="wrapper">

      <div class="content-wrap">
        <!-- // 是否加密 -->
        <div class="item-title">
          <Icon type="ios-lock-outline" />
          <span class="title-text">{{ $t('file.share.encrypt') }}</span>
        </div>
        <RadioGroup class="item-wrap" v-model="encrypt">
          <Radio :label="0">
            <span>{{ $t('file.share.no') }}</span>
          </Radio>
          <Radio :label="1">
            <span>{{ $t('file.share.yes') }}</span>
          </Radio>
        </RadioGroup>

        <!-- // 阅后即焚 -->
        <div class="item-title">
          <Icon type="ios-lock-outline" />
          <span class="title-text">{{ $t('file.share.once') }}</span>
        </div>
        <RadioGroup class="item-wrap" v-model="once">
          <Radio :label="0">
            <span>{{ $t('file.share.no') }}</span>
          </Radio>
          <Radio :label="1">
            <span>{{ $t('file.share.yes') }}</span>
          </Radio>
        </RadioGroup>

        <!-- // 有效期 -->
        <div class="item-title">
          <Icon type="ios-lock-outline" />
          <span class="title-text">{{ $t('file.share.dateTime') }}</span>
        </div>
        <RadioGroup class="item-wrap" v-model="effectiveDays">
          <Radio :label="30">
            <span>{{ $t('file.share.day', { day: 30 }) }}</span>
          </Radio>
          <Radio :label="7">
            <span>{{ $t('file.share.day', { day: 7 }) }}</span>
          </Radio>
          <Radio :label="1">
            <span>{{ $t('file.share.day', { day: 1 }) }}</span>
          </Radio>
        </RadioGroup>

        <!-- // 是否可保存 -->
        <template v-if="props.type === 'audio'">
          <div class="item-title">
            <Icon type="ios-lock-outline" />
            <span class="title-text">{{ $t('file.share.isSave') }}</span>
          </div>
          <RadioGroup class="item-wrap" v-model="save">
            <Radio :label="1">
              <span>{{ $t('file.share.save') }}</span>
            </Radio>
            <Radio :label="0">
              <span>{{ $t('file.share.nosave') }}</span>
            </Radio>
          </RadioGroup>
        </template>

        <Button type="primary"  @click="doGetLink">
          <Icon type="ios-link-outline" :loading="loading"/>{{ $t('file.share.getLinkBtn') }}
        </Button>

      </div>
    </div>

  </Modal>
</template>

<script setup>
import { shareFile } from '@/api/folder'
import { ref, defineExpose, defineProps, reactive, watch, getCurrentInstance } from 'vue'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'

const _this = getCurrentInstance().appContext.config.globalProperties

const props = defineProps({
  type: String,
  itemTimes: Array,
  fileCode: String,
  fileTitle: String
})

const isShow = ref(false)

// 是否加密
const encrypt = ref(1)

// 阅后即焚
const once = ref(0)

// 有效期
const effectiveDays = ref(7)

// 是否可保存
const save = ref(0)

// 分享成功
const hasCode = ref(false)

const shareResult = reactive({
  url: '',
  checkCode: 0
})

// 获取分享链接
const loading = ref(false)

/**
* @description: 获取文件信息
* @author: liuhua.chen
* @Date: 2024-11-18 11:38:33
*/
function doGetLink () {
  loading.value = true
  const data = { fileCode: props.fileCode, effectiveDays: effectiveDays.value, encrypt: encrypt.value, once: once.value, save: save.value }
  if (props.type !== 'audio') {
    const itemTimes = props.itemTimes.map((item) => {
      return item.itemTime
    })
    data.itemTimes = itemTimes.join(',')
  }
  shareFile(data).then((response) => {
    Object.assign(shareResult, {
      url: response.data?.shareUrl,
      checkCode: response.data?.checkCode
    })
    hasCode.value = true
  }).finally(() => {
    loading.value = false
  })
}
/**
 * @description: 复制链接
 * @author: liuhua.chen
 * @Date: 2024-11-18 15:15:22
 */
function doCopyLink () {
  navigator.clipboard.writeText(shareResult.url).then(() => {
    _this.$Message.success(_this.$t('file.copySuccess'))
  })
}
/**
 * @description: 复制链接及提取码
 * @author: liuhua.chen
 * @Date: 2024-11-18 15:17:58
 */
function doCopyCode () {
  const content = _this.$t('file.share.getLinkBtn', { username: 'xxxx', title: props.fileTitle, url: shareResult.url, checkCode: shareResult.checkCode })
  navigator.clipboard.writeText(content).then(() => {
    _this.$Message.success(_this.$t('file.copySuccess'))
  })
}

const init = () => {
  isShow.value = true
}

watch(isShow, (val) => {
  if (val) {
    hasCode.value = false
    save.value = 0
    encrypt.value = 1
    once.value = 0
    effectiveDays.value = 7
  }
})

// 函数暴露
defineExpose({
  init
})
</script>

<style scoped lang='less'>
.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.item-title {
  margin: 15px 0;
  color: #000000d9;

  .title-text {
    margin-left: 5px;
  }
}

.item-wrap {
  margin-bottom: 20px;
}

.content-wrap {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.top-wrap {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
  flex-direction: row;

  .text-wrap {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    width: 180px;
  }

  .sub-text {
    color: #aaa;
    font-size: 12px;
  }

  .top-text {
    color: #66cc5f;
    font-size: 16px;
  }
}

.input-wrap {
  width: 95%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  align-items: center;
  .title {
    width: 70px;
    margin-left: 10px;
  }
  .input {
    flex: 1;
    border-right: 0;
    /deep/ .ivu-input {
      border-radius: 0px;
    }
  }
  .password-input {
    width: 120px;
  }
  .link-btn {
    border-radius: 0;
  }
}
.password-input-wrap {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}
.qr-wrap {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-bottom: 5px;
}
.cancel-btn {
  align-self: center;
  margin-bottom: 10px;
  width: 180px;
}
.result-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
