<template>
  <Modal v-model="model" footer-hide :style="{padding: '0 0 24px'}">
    <div class="upload-body">
      <Steps class="step-wrap" :current="current">
        <Step :title="$t('home.editInfo')"></Step>
        <Step :title="$t('home.uploadFile')"></Step>
      </Steps>

      <!-- 编辑基础信息 -->
      <div v-if="current === 0">
        <div class="info-title">{{ $t('file.meetingname') }}</div>
        <Input class="info-input" v-model="fileTitle" :placeholder="form.fileTitle" clearable></Input>
        <div class="info-title">{{ $t('file.meetingPersonel') }}</div>
        <Input class="info-input" v-model="form.personnel" :placeholder="$t('file.editPlaceHolder')" clearable></Input>
        <div class="info-title">{{ $t('file.meetingAdress') }}</div>
        <Input class="info-input" v-model="form.address" :placeholder="$t('file.editPlaceHolder')" clearable></Input>

        <Button type="primary" @click="doStep(1)">{{ $t('home.next') }}</Button>
      </div>

      <!-- 上传录音文件 -->
      <div v-if="current === 1">
        <Form ref="secondForm" label-position="top" :model="secondFormInfo" :rules="ruleValidate">
          <FormItem prop="selectFile">
            <Upload
            type="drag"
            :before-upload="doBeforeUpload"
            action="#">
              <div style="padding: 10px 0">
                  <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                  <p class="upload-text ">{{ $t('home.uploadHint') }}</p>
              </div>
          </Upload>

          <div v-if="secondFormInfo.selectFile" class="file-item-wrap">
            <div><Icon type="md-attach" />{{ secondFormInfo.selectFile.name }}</div>
            <Button class="delete-icon" type="text" icon="md-trash" size="small" @click="doDelete"></Button>
          </div>

          </FormItem>

          <FormItem prop="checked">
            <Checkbox  v-model="secondFormInfo.checked">{{ $t('home.privacy1') }}《用户协议》和《隐私政策》{{ $t('home.privacy2') }}</Checkbox>
          </FormItem>

          <FormItem :label="$t('home.recogiz')" required>
            <Select v-model="secondFormInfo.lang" transfer class="origin-select">
              <Option v-for="item in tranlateLanguage" :value="item.lang" :key="item.lang">{{ item.label }}</Option>
            </Select>
          </FormItem>

        </Form>

        <div class="lang-btn-wrap">
          <Button @click="doStep(0)">{{ $t('home.preview') }}</Button>
          <Button type="primary" class="btn" @click="doSubmit">{{ $t('file.submit') }}</Button>
        </div>

      </div>

    </div>
  </Modal>
</template>

<script setup>
import { getTimeStamp2DateStr } from '@/utils/util'
import { ref, reactive, watch, getCurrentInstance, defineModel } from 'vue'
import { tranlateLanguage } from '@/libs/config'
import { FormItem } from 'view-ui-plus'

const secondForm = ref(null)
// 弹窗是否显示
const model = defineModel()

const _this = getCurrentInstance().appContext.config.globalProperties

const current = ref(0)

const fileTitle = ref('')

const form = reactive({
  fileTitle: '',
  personnel: '',
  address: ''
})

const secondFormInfo = reactive({
  selectFile: '',
  checked: '',
  lang: ref(tranlateLanguage[0].lang)
})

const ruleValidate = {
  selectFile: [{
    required: true, message: _this.$t('home.selectFileHint')
  }],
  checked: [{
    required: true, message: _this.$t('home.privacyHint')
  }]
}

/**
 * @description: 上一步或下一步
 * @author: liuhua.chen
 * @Date: 2024-11-25 16:24:40
 */

function doStep (step) {
  current.value = step
}

function doBeforeUpload (file) {
  secondFormInfo.selectFile = file
  return false
}

function doDelete () {
  secondFormInfo.selectFile = ''
}

function doSubmit () {
  secondForm.value.validate((valid) => {
    _this.$Message.info('功能待开发')
  })
}

function resetData () {
  current.value = 0
  form.fileTitle = getTimeStamp2DateStr(Date.now(), 'yyyy_MM_dd_hh:mm:ss')
  form.address = ''
  form.personnel = ''
  secondFormInfo.selectFile = ''
  secondFormInfo.checked = ''
  secondFormInfo.lang = ref(tranlateLanguage[0].lang)
}

watch(() => model.value, (val) => {
  if (val) {
    resetData()
  }
})

</script>

<style scoped lang='less'>
.wrapper {
  display: flex;
}
.info-title {
  align-items: center;
  max-width: 100%;
  height: 32px;
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
}
.upload-body {
  padding: 20px 30px;
}
.step-wrap {
  margin-bottom: 30px;
}
.info-input {
  margin-bottom: 20px;
}
.upload-sub-text {
  color: rgba(0, 0, 0, .45);
  font-size: 14px;
}
.upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, .85);
  font-size: 16px;
}
.upload-bottom {
  width: 80%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.lang-title {
  margin-top: 20px;
  margin-bottom: 10px;
}
.lang-btn-wrap {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  .btn {
    margin-left: 10px;
  }
}
.file-item-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  &:hover {
    background-color: rgb(241, 231, 231);
    .delete-icon {
      display: block;
    }
  }
  .delete-icon {
    display: none
  }
}
</style>
