<template>
  <ConfirmModal v-model="model"  :ok-loading="loading" @on-cancel="onCancel" @on-ok="onSubmit">
    <EditInfoBody ref="infoBody" :file-info="fileInfo" @on-commit-start="() => loading = true" @on-finish="() => loading = false" @on-success="doOk"></EditInfoBody>
  </ConfirmModal>
</template>

<script setup>
import { ref, defineEmits, defineProps, defineModel } from 'vue'
import ConfirmModal from '@/components/common/confirm-modal.vue'
import EditInfoBody from './edit-info-body.vue'

defineProps({
  fileInfo: Object
})

const emit = defineEmits(['on-success', 'update:modelValue'])

// 弹窗是否显示
const model = defineModel()

const loading = ref(false)
const infoBody = ref(null)

function onSubmit () {
  infoBody.value.onConfirm()
}

function doOk () {
  emit('update:modelValue', false)
  emit('on-success')
}

function onCancel () {
  emit('update:modelValue', false)
}

</script>

<style scoped lang='less'>

</style>
