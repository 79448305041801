/*
 * @feature: 事件总线
 * @author: liuhua.chen
 * @Date: 2024-11-13 09:00:36
 */

import mitt from 'mitt'

const emitter = mitt()

export default emitter
