/*
 * @feature:
 * @author: liuhua.chen
 * @Date: 2024-11-12 15:57:45
 */
import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/main/main-layout.vue'
import Home from '../views/home/home.vue'
const Cooperation = () => import('../views/cooperation.vue')
const Pay = () => import('../views/pay.vue')
const Message = () => import('../views/message.vue')

const Recycle = () => import('../views/recycle.vue')
const RecordMain = () => import('../views/recordlist/index.vue')
const Login = () => import('../views/login.vue')
const UserCenter = () => import('../views/user/userCenter.vue')
const AccountSecurity = () => import('../views/user/accountSecurity.vue')
const CloudManager = () => import('../views/user/cloudManager.vue')
const MyOrder = () => import('../views/user/myOrder.vue')
const Store = () => import('../views/user/store.vue')
const RecordDetail = () => import('../views/recordlist/detail.vue')
const ShareDetail = () => import('../views/recordlist/share-detail.vue')
const ShareSuccess = () => import('../views/recordlist/share-success.vue')
const Live = () => import('../views/home/live.vue')

const routes = [
  {
    path: '/',
    name: 'main',
    component: HomeView,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        meta: {
          title: 'navigator.home'
        },
        component: Home
      },
      {
        path: '/cooperation',
        name: 'cooperation',
        meta: {
          title: 'navigator.cooperation'
        },
        component: Cooperation
      },
      {
        path: '/pay',
        name: 'pay',
        meta: {
          title: 'navigator.pay'
        },
        component: Pay
      },
      {
        path: '/message',
        name: 'message',
        meta: {
          title: 'navigator.message'
        },
        component: Message
      },
      {
        path: '/recycle',
        name: 'recycle',
        meta: {
          title: 'navigator.recycle'
        },
        component: Recycle
      },
      {
        path: '/recordlist/:folderCode',
        name: 'recordlist',
        component: RecordMain
      },
      {
        path: '/userCenter',
        name: 'userCenter',
        component: UserCenter
      },
      {
        path: '/accountSecurity',
        name: 'accountSecurity',
        component: AccountSecurity
      },
      {
        path: '/myOrder',
        name: 'myOrder',
        component: MyOrder
      },
      {
        path: '/store',
        name: 'store',
        component: Store
      },
      {
        path: '/cloudManager',
        name: 'cloudManager',
        component: CloudManager
      },
      {
        path: '/recorddetail/:fileCode',
        name: 'recorddetail',
        title: '转写详情',
        component: RecordDetail
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'login.title'
    },
    component: Login
  },
  {
    path: '/share',
    name: 'share',
    component: ShareDetail
  },
  {
    path: '/share/success',
    name: 'sharesuccess',
    component: ShareSuccess
  },
  {
    path: '/live',
    name: 'live',
    component: Live
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
