<!--
 * @feature: 首页
 * @author: liuhua.chen
 * @Date: 2024-11-01 11:06:45
-->
<template>
  <div class="main-content wrapper">
    <div class="top-wrap">
      <div class="brand-wrap">
        <span class="text">{{ $t('appName') }}</span>
        <Poptip trigger="hover">
          <span class="pro">PRO</span>
          <template #content>
            <span>{{ $t('home.openHint') }}</span>
            <div class="open-btn">{{ $t('home.open') }}</div>
          </template>
        </Poptip>
      </div>
      <SwitchLanguage />
    </div>

    <div class="sub-title-wrap">
      <div class="left-wrap">
        <div class="sub-title page-sub-title-text">{{ $t('home.recentRecord') }}</div>
        <Icon type="ios-refresh" class="refresh-icon" @click="doRefresh"/>
      </div>

      <div class="right-wrap">
        <Tooltip v-if="showSyncUpload">
          <template #content>
            <div style="white-space: normal">{{ $t('home.syncHint', {count: syncNum})}}</div>
          </template>
          <Badge :count="syncNum" :offset="[5,5]"><Button type="text" class="async-btn" icon="ios-cloud-upload-outline" @click="openSyncModal">{{ $t('home.syncBtn')}}</Button></Badge>
        </Tooltip>
        <Button shape="circle" class="btn" icon="ios-cloud-upload-outline" type="primary" @click="doUploadAudio">{{ $t('home.upload') }}</Button>
        <Button shape="circle" class="btn" icon="ios-mic-outline" type="primary" @click="openSelectLang">{{ $t('home.audioWrite') }}</Button>
        <Button shape="circle" class="btn" icon="logo-angular" type="primary"  @click="openSelectLang(1)">{{ $t('home.audioTrans') }}</Button>
      </div>

    </div>
    <!-- 内容部分 -->
    <div class="middle-content">
      <LayoutEmpty class="empty-layout" v-if="showEmpty" />
      <div v-else class="content-wrap">
        <div v-for="(item, index) in fileList" :key="index" class="item-wrap" @click="doItemClick(item)">
          <div class="item-left-wrap">
            <span class="title">{{ item.fileTitle }}</span>
            <div class="sub-title">
              <Badge status="success" text="转写成功" />
              <!-- 创建时间 -->
              <div class="time-icon">
                <Icon class="icon" type="ios-calendar-outline" /> {{ getTime(item.fileCreateTime) }}
              </div>
              <!-- 时长 -->
              <div class="time-icon">
                <Icon class="icon" type="md-time" /> {{ getSecond(item.originFileDuration) }}
              </div>
            </div>
          </div>
          <Dropdown @on-click="(name) => onMoreClick(name, item)">
            <a href="javascript:void(0)">
              <Icon type="ios-more" color="#ebcaa1"/>
            </a>
            <template #list>
              <DropdownMenu>
                <DropdownItem name="share"><Icon type="md-share" class="drop-icon"/>{{ $t('file.share.audioTitle') }}</DropdownItem>
                <DropdownItem name="edit"><Icon type="ios-alert-outline" class="drop-icon"/>{{ $t('file.editInfo') }}</DropdownItem>
                <DropdownItem name="trans"><Icon type="ios-fastforward-outline" class="drop-icon"/>{{ $t('home.reWrite') }}</DropdownItem>
                <DropdownItem name="audio"><Icon type="ios-cloud-download-outline" class="drop-icon"/>{{ $t('file.exportMusic') }}</DropdownItem>
                <DropdownItem name="file"><Icon type="ios-cloud-download-outline" class="drop-icon"/>{{ $t('file.exportOri') }}</DropdownItem>
                <DropdownItem name="delete"><Icon type="ios-trash-outline" class="drop-icon"/>{{ $t('file.deleteFile') }}</DropdownItem>
              </DropdownMenu>
            </template>
          </Dropdown>
        </div>
        <div class="page-wrap">
          <Page size="small" class="mt-meeting-page" :total="total"  v-model:current="pageNum" :page-size="pageSize" @on-change="onPageChange" />
        </div>

      </div>
      <Spin v-if="loading" fix></Spin>
    </div>
    <!-- 分享音频链接 -->
    <ShareDigestModal v-if="showShareModal" ref="shareModal" :fileTitle="currentFile.fileTitle" type="audio" :fileCode="currentFile.fileCode"></ShareDigestModal>
    <!-- 删除文件 -->
    <DeleteFileModal v-model="showDeleteModal"  :fileCode="currentFile.fileCode" :fileTitle="currentFile.fileTitle" @on-success="() => getData()"/>
    <!-- 修改信息 -->
    <EditInfoModal v-model="showEditModal" :file-info="currentFile"  @on-success="() => getData()"></EditInfoModal>
    <!-- 转写设置 -->
    <RetranModal v-model="showTranModal"></RetranModal>
    <!-- 上传音频 -->
    <UploadAudioModal v-model="showUpload"></UploadAudioModal>
    <!-- 声文速记 -->
    <SelectLangDraw v-model="showSelectLangDraw" :type="selectLangType"></SelectLangDraw>
    <!-- 待同步的音频对话框 -->
    <SyncFileModal v-model="showSyncFileModal" @on-ok="getSyncFile"></SyncFileModal>

  </div>
</template>

<script setup>
import { getList } from '@/api/folder'
import LayoutEmpty from '@/components/layout-empty.vue'
import SwitchLanguage from '@/components/switch_language.vue'
import { getTimeStamp2DateStr, formatSecond, downLoadFile, getSuffix } from '@/utils/util'
import ShareDigestModal from '@/components/file/share-digest-modal.vue'
import { nextTick, onMounted, reactive, ref, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import DeleteFileModal from '@/components/file/delete-file-modal.vue'
import EditInfoModal from '@/components/home/edit-info-modal.vue'
import RetranModal from '@/components/home/retran-modal.vue'
import UploadAudioModal from '@/components/home/upload-audio-modal.vue'
import SelectLangDraw from '@/components/home/select-lang-draw.vue'
import IndexDBCache from '@/libs/indexDb'
import lockr from 'lockr'
import { Tooltip } from 'view-ui-plus'
import SyncFileModal from '@/components/home/sync-file-modal.vue'

const _this = getCurrentInstance().appContext.config.globalProperties

const fileList = reactive([])
// 加载数据
const loading = ref(false)
// 是否显示没有数据
const showEmpty = ref(true)
// 总的数据
const total = ref(0)
// 页数
const pageSize = ref(8)
const pageNum = ref(0)

const router = useRouter()

const showShareModal = ref(false)
const currentFile = ref({})
const shareModal = ref(null)

// 显示删除对话框
const showDeleteModal = ref(false)

// 编辑信息
const showEditModal = ref(null)

// 转写
const showTranModal = ref(false)

// 上传
const showUpload = ref(false)

// 声文速记
const showSelectLangDraw = ref(false)
const selectLangType = ref('')

// 下载work
const downloadWorker = ref(null)

// 是否有待同步的文件
const showSyncUpload = ref(false)
const syncNum = ref(0)
const showSyncFileModal = ref(false)

onMounted(() => {
  getData()
  getSyncFile()
})
/**
 * @description: 获取数据中待同步的文件
 * @author: liuhua.chen
 * @Date: 2024-12-03 08:19:45
 */
function getSyncFile () {
  const recordInfo = lockr.get('record')
  // 如果localstorage有信息，直接读取
  if (recordInfo) {
    syncNum.value = 1
    showSyncUpload.value = true
    return
  }

  // 没有读取数据库的信息
  const indexDb = new IndexDBCache()
  // 查询数据
  indexDb.getDataByKey().then((res) => {
    if (res?.data) {
      showSyncUpload.value = true
      syncNum.value = 1
    } else {
      showSyncUpload.value = false
      syncNum.value = 0
    }
    // 关闭数据库
    indexDb.closeDB()
  })
}

/**
 * @description: 加载数据
 * @author: liuhua.chen
 * @Date: 2024-11-25 09:50:55
 */
function getData () {
  loading.value = true
  getList({ folderCode: '', current: pageNum.value, size: pageSize.value }).then((data) => {
    showEmpty.value = data.total === 0

    total.value = data.total
    fileList.length = 0
    fileList.push(...data.data)
  }).finally(() => {
    loading.value = false
  })
}
/**
 * @description: 获取创建时间
 * @param {*} time
 * @author: liuhua.chen
 * @Date: 2024-11-05 16:18:51
 */
function getTime (time) {
  return getTimeStamp2DateStr(time, 'MM/dd, hh:mm')
}
/**
 * @description: 文件所在文件夹的颜色
 * @param {*} item
 * @author: liuhua.chen
 * @Date: 2024-11-05 16:36:29
 */
function getSecond (second) {
  return formatSecond(second)
}
/**
 * @description: 进入详情
 * @param {*} item
 * @author: liuhua.chen
 * @Date: 2024-11-25 10:34:28
 */
function doItemClick (item) {
  router.push(`/recorddetail/${item.fileCode}`)
}
/**
 * @description: 更多操作
 * @param {*} name
 * @author: liuhua.chen
 * @Date: 2024-11-25 11:26:25
 */
function onMoreClick (name, item) {
  currentFile.value = item
  if (name === 'share') {
    showShareModal.value = true
    nextTick(() => {
      shareModal.value.init()
    })

  // 导出音频
  } else if (name === 'audio') {
    const url = item.originFilePath || ''
    if (!url) {
      _this.$Message.error('下载失败，音频地址为空')
      return
    }

    const subfix = getSuffix(url.substr(0, url.lastIndexOf('?')))
    const name = item.fileTitle + subfix

    downloadWorker.value = downLoadFile({
      downloadWorker: downloadWorker.value,
      url,
      name
    })
  // 删除文档
  } else if (name === 'delete') {
    showDeleteModal.value = true

  // 编辑信息
  } else if (name === 'edit') {
    showEditModal.value = true

  // 重新转写
  } else if (name === 'trans') {
    showTranModal.value = true
  } else if (name === 'file') {
    _this.$Message.info('功能待开发')
  }
}

function onPageChange (page) {
  pageNum.value = page
  getData()
}

function doRefresh () {
  getData()
}

function openSelectLang (type) {
  showSelectLangDraw.value = true
  selectLangType.value = type === 1 ? 'simul' : ''
}
/**
 * @description: 上传音频
 * @author: liuhua.chen
 * @Date: 2024-11-25 16:13:08
 */
function doUploadAudio () {
  showUpload.value = true
}

function openSyncModal () {
  showSyncFileModal.value = true
}

</script>

<style scoped lang='less'>
@import '~@/styles/index.less';
@import "~@/styles/mixins.less";
@import "~@/styles/theme.less";

.wrapper {
  height: 100%;
}

.top-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 10px;

  .brand-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;

    .text {
      font-size: 20px;
      color: @primary-color;
    }

    .pro {
      cursor: pointer;
      background-color: rgb(221, 214, 214);
      border-radius: 5px;
      margin-left: 5px;
      padding: 2px 8px;
      font-size: 12px;
    }

    .open-btn {
      cursor: pointer;
      margin-top: 8px;
      display: flex;
      justify-content: flex-end;
      color: @primary-color;
    }
  }
}

.sub-title-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;

  .left-wrap {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .refresh-icon {
    margin-left: 5px;
    color: @primary-color;
    cursor: pointer;
    font-size: 24px;
  }

  .right-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    .btn {
      margin-left: 8px;
      font-size: 16px;
      padding: 0 15px;
      height: 40px;
    }
  }
}

.content-wrap {
  padding: 0 20px;
  background-color: white;
  border-radius: 5px;
}

.empty-layout {
  width: 100%;
  height: 300px;
}

.item-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-bottom: @divideColor;

  &:hover {
    background-color: rgb(250, 244, 244);
  }

  .item-left-wrap {
    display: flex;
    flex-direction: column;

    .title {
      margin-bottom: 4px;
      color: #111;
      font-size: 14px;
      line-height: 1.5715;
    }

    .sub-title {
      display: flex;
      flex-direction: row;
    }

    .time-icon {
      margin-left: 20px;
      color: #aaa;
      font-size: 14px;
      line-height: 1.5715;
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        margin-right: 5px;
      }
    }
  }
  .drop-icon {
    margin-right: 5px;
  }
}
.page-wrap {
  padding: 10px 0 20px 0;
}
.middle-content {
  min-height: calc(100% - 150px);
  position: relative;
}
.async-btn {
  color: red;
}
</style>
