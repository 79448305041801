<!--
 * @feature: 待同步的音频对话框
 * @author: liuhua.chen
 * @Date: 2024-12-03 08:28:48
-->
<template>
  <Modal v-model="model" class='wrapper' :title="title" footer-hide>
    <List>
      <ListItem v-for="(item, index) in fileList" :key="index">
        <ListItemMeta :title="item.title"></ListItemMeta>
        <template #action>
          <li>
            <div class="text-btn" @click="doDelete(item)"><Icon class="icon" type="ios-trash-outline" />{{ $t('common.delete') }}</div>
          </li>
          <li>
            <div class="text-btn" @click="doDownload(item)"><Icon class="icon" type="ios-cloud-upload-outline"/>{{ $t('home.download') }}</div>
          </li>
          <li>
            <Dropdown @on-click="onDropdownClick">
              <div class="text-btn"><Icon class="icon" type="ios-sync" />{{ $t('home.sync') }}</div>
              <template #list>
                <DropdownItem v-for="(item, index) in langList" :key="index" :name="item.lang">{{ item.label }}</DropdownItem>
              </template>
            </Dropdown>
          </li>
        </template>
      </ListItem>
    </List>
  </Modal>
</template>

<script setup>
import { ref, defineModel, onMounted, watch, reactive, onUnmounted, getCurrentInstance, defineEmits } from 'vue'
import IndexDBCache from '@/libs/indexDb'
import { tranlateLanguage } from '@/libs/config'
import lockr from 'lockr'

const _this = getCurrentInstance().appContext.config.globalProperties

const emit = defineEmits(['update:modelValue', 'on-ok'])

const model = defineModel()

const title = ref('')

const num = ref(0)

const fileList = reactive([])

const langList = tranlateLanguage

let indexDb

onMounted(() => {
  indexDb = new IndexDBCache()
})

onUnmounted(() => {
  indexDb.closeDB()
})
/**
 * @description: 下载文件
 * @param {*} item
 * @author: liuhua.chen
 * @Date: 2024-12-03 09:54:24
 */
function doDownload (item) {
  // 下载文件
  const url = window.URL.createObjectURL(item.file)
  const a = document.createElement('a')
  a.href = url
  a.download = item.title
  a.click()
  window.URL.revokeObjectURL(url)
}
/**
 * @description: 同步
 * @param {*} lang
 * @author: liuhua.chen
 * @Date: 2024-12-03 10:32:10
 */
function onDropdownClick (lang) {

}
/**
 * @description: 删除文件
 * @author: liuhua.chen
 * @Date: 2024-12-03 10:35:28
 */
function doDelete (item) {
  indexDb.remove(item.blob).then(() => {
    if (fileList.length === 1) {
      lockr.rm('record')
      emit('update:modelValue', false)
      emit('on-ok')
    }
  }, (e) => {
    console.log('删除数据失败:', e)

    _this.$Message.error('删除失败')
  })
}

watch(() => model.value, (val) => {
  indexDb.readAll().then((res) => {
    num.value = res.length
    title.value = _this.$t('home.syncModalTitle', { count: num.value })
    fileList.length = 0
    fileList.push(...res)
  })
})

</script>

<style scoped lang='less'>
@import '~@/styles/theme.less';
.icon {
  font-size: 16px;
  margin-right: 3px;
}
</style>
