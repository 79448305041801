/* eslint-disable vue/multi-word-component-names */
/*
 * @feature:
 * @author: liuhua.chen
 * @Date: 2024-11-12 15:57:45
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n, loadLanguageAsync } from '@/lang/index'
import { Dropdown, DropdownMenu, DropdownItem, Button, Icon, Carousel, CarouselItem, MenuItem, Submenu, Menu, MenuGroup, Avatar, Modal, Message, Content, Footer, Layout, Tabs, TabPane, Input, Checkbox, Spin, Tooltip, Page, Card, CheckboxGroup, Poptip, Drawer, Radio, RadioGroup, Switch, Row, Col, Slider, Form, FormItem, Upload, Image, Select, Option, FooterToolbar, Affix, Result, Badge, Steps, Step, Notice, List, ListItem, ListItemMeta, Space, DatePicker, Login, Mobile, Captcha } from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import './styles/index.less'
import './styles/common.css'
import { getBrowserLang, setTitle } from './utils/util'
const app = createApp(App)

app.component('Dropdown', Dropdown)
app.component('DropdownMenu', DropdownMenu)
app.component('DropdownItem', DropdownItem)
app.component('Button', Button)
app.component('Icon', Icon)
app.component('Carousel', Carousel)
app.component('CarouselItem', CarouselItem)
app.component('MenuItem', MenuItem)
app.component('Submenu', Submenu)
app.component('Menu', Menu)
app.component('MenuGroup', MenuGroup)
app.component('Avatar', Avatar)
app.component('Modal', Modal)
app.component('Message', Message)
app.component('Content', Content)
app.component('Footer', Footer)
app.component('Layout', Layout)
app.component('Tabs', Tabs)
app.component('TabPane', TabPane)
app.component('Input', Input)
app.component('Checkbox', Checkbox)
app.component('Spin', Spin)
app.component('Tooltip', Tooltip)
app.component('Page', Page)
app.component('Card', Card)
app.component('CheckboxGroup', CheckboxGroup)
app.component('Poptip', Poptip)
app.component('Drawer', Drawer)
app.component('Radio', Radio)
app.component('RadioGroup', RadioGroup)
app.component('IviewSwitch', Switch)
app.component('Row', Row)
app.component('Col', Col)
app.component('Slider', Slider)
app.component('Form', Form)
app.component('FormItem', FormItem)
app.component('Upload', Upload)
app.component('Image', Image)
app.component('Select', Select)
app.component('Option', Option)
app.component('FooterToolbar', FooterToolbar)
app.component('Affix', Affix)
app.component('Result', Result)
app.component('Badge', Badge)
app.component('Step', Step)
app.component('Steps', Steps)
app.component('List', List)
app.component('ListItem', ListItem)
app.component('ListItemMeta', ListItemMeta)
app.component('Space', Space)
app.component('DatePicker', DatePicker)
app.component('Login', Login)
app.component('Mobile', Mobile)
app.component('Captcha', Captcha)

app.use(i18n)
app.use(store)
app.use(router)

// 全局挂载属性
app.config.globalProperties.$Modal = Modal
app.config.globalProperties.$Message = Message
app.config.globalProperties.$Notice = Notice
app.config.globalProperties._i18n = i18n
app.config.globalProperties.$t = i18n.global.t

window.$Message = Message
window.router = router
// 获取当前浏览器的语言
const lang = getBrowserLang('-').i18nLang

router.beforeEach((to, from, next) => {
  // 设置标题
  if (to.meta?.title) {
    setTitle(i18n.global.t(to.meta.title) + '-' + i18n.global.t('appName'))
  } else {
    setTitle(i18n.global.t('appName'))
  }

  next()
})

loadLanguageAsync(lang).then(() => {
  app.mount('#app')
})
