<!--
 * @feature:
 * @author: liuhua.chen
 * @Date: 2024-11-01 09:11:56
-->
<template>
  <div class="main-side" :class="{ 'menu-folded': folded }">
    <div class="logo-and-btn">
      <div class="brand-logo">
        <Dropdown @on-click="clickUserDetail" trigger="hover">
            <Avatar v-if="userInfo.avatar" class="brand-logo-img" :src="userInfo.avatar"/>
            <Avatar v-else class="brand-logo-img" icon="ios-person"/>
          <template #list>
            <DropdownMenu>
                <DropdownItem class="user-dropdown-item" name="id_center" >个人中心</DropdownItem>
                <DropdownItem class="user-dropdown-item" name="id_account" >账号安全</DropdownItem>
                <DropdownItem class="user-dropdown-item" name="id_logout" divided>退出登录</DropdownItem>
            </DropdownMenu>
          </template>
        </Dropdown>        <span class="brand-logo-name">{{  userInfo.nickname }}</span>
      </div>
      <div class="fold-menu-btn">
        <!-- <Icon class="iwbfont" custom="iwb-menu-switch" v-clickLimiter @click="onclickFoldMenu" /> -->
      </div>
    </div>
    <div class="content-wrap">
      <div class="menu-wrap">
        <Menu ref="menu" class="menu-ul" theme="light"  :active-name="currentMenu" width="auto">
          <!-- 首页 -->
          <MenuItem class="menu-item" name="home" to="/home" :title="$t('navigator.home')">
            <Icon type="ios-home-outline" class="menu-item-icon" :class="{'folder-menu-item-icon': folded}"/><span class="menu-item-text">{{ $t("navigator.home") }}</span>
          </MenuItem>
          <!-- 录音列表 -->
          <Submenu name="record" class="menu-item record">
            <template v-slot:title>
              <Icon type="md-folder-open" class="menu-item-icon"/><span class="menu-item-text">{{  $t('navigator.record') }}</span>
            </template>

            <MenuItem v-for="(item, index) in recordMenus" :key="index" :name="'recordlist/'+item.folderCode" :to="'/recordlist/'+item.folderCode"  >
              <div class="sub-item-wrap">
                <div class="sub-item-left-wrap">
                  <Icon :type="item.icon" class="menu-item-icon" :color="item.color"/><span class="menu-item-text" :class="{'sub-menu-item-text': item.folderCode != '0' && item.folderCode != 'STAR'}">{{  item.folderName }}</span><span v-if="item.fileCount > 0" class="count-text">{{ item.fileCount }}</span>
                </div>
                <!-- 尾部操作栏 -->
                <div class="oprator-wrap">
                  <!-- 添加文件夹 -->
                  <!-- <Tooltip v-if="item.folderCode == '0'" content="创建" placement="top" transfer> -->
                    <Icon v-if="item.folderCode == '0'" type="md-add" @click="onCreateFolder"/>
                  <!-- </Tooltip> -->
                  <div v-else-if="item.folderCode != 'STAR'">
                    <!-- 编辑文件 -->
                    <!-- <Tooltip content="编辑" placement="top" transfer> -->
                      <Icon class="oprator-icon" type="ios-create-outline" size="18" @click="onEditFolder(item)"/>
                    <!-- </Tooltip> -->
                    <!-- 删除文件 -->
                    <!-- <Tooltip content="删除" placement="top" transfer> -->
                      <Icon type="ios-trash-outline" size="18" @click="deleteFolder(item)"/>

                    <!-- </Tooltip> -->
                  </div>
                </div>
              </div>
            </MenuItem>

          </Submenu>

          <!-- 回收站 -->
          <MenuItem class="menu-item" name="recycle" to="/recycle"  :title="$t('navigator.recycle')">
            <Icon type="ios-trash-outline" class="menu-item-icon"/><span class="menu-item-text">{{  $t('navigator.recycle') }}</span>
          </MenuItem>
          <!-- 充值购买 -->
          <MenuItem class="menu-item" name="pay" :title="$t('navigator.pay')">
            <Icon type="ios-cart" class="menu-item-icon"/><span class="menu-item-text">{{  $t('navigator.pay') }}</span>
          </MenuItem>
          <!-- 商务合作 -->
          <MenuItem class="menu-item" name="cooperation" :title="$t('navigator.cooperation')">
            <Icon type="ios-hand" class="menu-item-icon"/><span class="menu-item-text">{{  $t('navigator.cooperation') }}</span>
          </MenuItem>
          <!-- 消息通知 -->
          <MenuItem class="menu-item" name="message"  :title="$t('navigator.message')">
            <Icon type="ios-notifications" class="menu-item-icon"/><span class="menu-item-text">{{  $t('navigator.message') }}</span>
          </MenuItem>
        </Menu>

        <!-- 到期时间 -->
        <div class="menu-bottom-btn-wrap">
          <Icon class="icon-img" type="ios-analytics-outline" />
          <div v-if="!folded" class="btn-text-wrap">
            <div class="text">PRO</div>
            <div class="sub-text">{{ $t('navigator.dateLine', { 0: '2024-11-45'})  }}</div>
          </div>
        </div>

        <!-- 可用时长 -->
        <div class="menu-bottom-btn-wrap">
          <Icon class="icon-img" type="ios-pulse-outline" />
          <div v-if="!folded" class="btn-text-wrap">
            <div class="text">{{  $t('navigator.useTime') }}</div>
            <div class="sub-text">{{ $t('navigator.callTime', { 0: '31.6 小时'})  }}</div>
          </div>
        </div>

      </div>
    </div>
    <div class="bottom-wrap">
      <Button icon="ios-menu" type="text" shape="circle" @click="onclickFoldMenu"></Button>
    </div>

    <CreateFolderModal v-model="showCreateModal" :folderLength="recordMenus.length" :fileInfo="editfileInfo" @on-success="onCreateOrEditFolderSuccess"/>

    <DeleteFolderModal v-model="showDeleteModal" :fileInfo="editfileInfo" @on-success="onCreateOrEditFolderSuccess"></DeleteFolderModal>
  </div>
</template>

<script setup>
import { getFolderList } from '@/api/folder'
import CreateFolderModal from '@/components/file/create-modal.vue'
import DeleteFolderModal from '@/components/file/delete-folder-modal.vue'
import EventBus from '@/EventBus'
import store from '@/store'
import lockr from 'lockr'
import { computed, nextTick, onActivated, onMounted, onUnmounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()

const currentMenu = ref('home')
const folded = ref(false)
const username = ref('')
const recordMenus = ref([])
const menuTag = {
  '/home': 'home',
  '/recycle': 'recycle',
  '/files': 'files',
  '/pay': 'pay',
  '/cooperation': 'cooperation',
  '/message': 'message'
}

const showCreateModal = ref(false)

const editfileInfo = reactive({})

const showDeleteModal = ref(false)

let route

onMounted(() => {
  route = useRoute()
  setCurrentMenu(route)
  getRecordMenus()
  EventBus.on('on-folder-change', getRecordMenus)
})

// 点击收起菜单
function onclickFoldMenu () {
  folded.value = !folded.value
}

function clickUserDetail (d) {
  switch (d) {
    case 'id_center':
      router.push('/userCenter')
      break

    case 'id_account':
      router.push('/accountSecurity')
      break

    case 'id_cloudManager':
      router.push('/cloudManager')
      break

    case 'id_store':
      router.push('/store')
      break

    case 'id_order':
      router.push('/myOrder')
      break

    case 'id_logout':
      // this.$router.push('/myOrder')
      break
  }
  console.log('clickUserDetail', d)
}
/**
     * 设置当前高亮菜单栏目
     */
function setCurrentMenu (to) {
  currentMenu.value = menuTag[to?.fullPath] || ''
}
/**
 * @description: 获取文件夹列表
 * @author: liuhua.chen
 * @Date: 2024-11-04 14:57:47
 */
function getRecordMenus () {
  getFolderList().then((data) => {
    const list = data.data?.folderList || []

    const totalCount = data.data?.totalCount || ''
    const menuList = [{
      folderName: '全部文件',
      folderCode: '0',
      fileCount: totalCount > 99 ? '99+' : totalCount,
      icon: 'ios-folder-open-outline',
      color: '#000000'
    }]

    menuTag['/recordlist/0'] = 'recordlist/0'

    const sortList = []
    const colorList = {}
    list.forEach(item => {
      if (item.folderCode === 'STAR') {
        item.icon = 'ios-star-outline'
        item.color = item.color || '#000000'
        item.fileCount = data.data?.starCount || 0
        menuList.push(item)
      } else if (item.folderCode !== 'RECYCLE') {
        item.icon = 'md-folder-open'
        item.color = item.color || '#000000'
        sortList.push(item)
      }
      colorList[item.folderCode] = item.color
      const key = 'recordlist/' + item.folderCode
      menuTag['/' + key] = key
    })

    sortList.sort((a, b) => a.sort - b.sort)
    store.commit('setColorList', colorList)

    recordMenus.value = menuList.concat(sortList)
    nextTick(() => {
      setCurrentMenu(route)
    })
  })
}
/**
 * @description: 创建文件夹
 * @author: liuhua.chen
 * @Date: 2024-11-05 08:35:04
 */
function onCreateFolder () {
  Object.assign(editfileInfo, {})
  showCreateModal.value = true
}
/**
 * @description: 创建编辑文件夹成功
 * @author: liuhua.chen
 * @Date: 2024-11-05 08:59:07
 */
function onCreateOrEditFolderSuccess () {
  if (currentMenu.value !== 'recordlist/0') {
    router.push('/recordlist/0')
  }
  getRecordMenus()
}
/**
 * @description: 编辑文件夹
 * @param {*} item
 * @author: liuhua.chen
 * @Date: 2024-11-05 09:11:02
 */
function onEditFolder (item) {
  Object.assign(editfileInfo, item)
  console.log('onEditFolder:', editfileInfo, item);
  
  showCreateModal.value = true
}
/**
 * @description: 删除文件夹
 * @author: liuhua.chen
 * @Date: 2024-11-05 09:44:07
 */
function deleteFolder (item) {
  Object.assign(editfileInfo, item)
  showDeleteModal.value = true
}

const userInfo = computed(() => {
  const user = store.state.user.userInfo || lockr.get('userInfo') || {}
  return user
})

onUnmounted(() => {
  EventBus.off('on-folder-change', getRecordMenus)
})

onActivated(() => {
  setCurrentMenu(router)
})

</script>

<style lang="less" scoped>
@import '~@/styles/mixins.less';
.main-side {
  position: relative;
  height: 100%;
  width: 250px;
  transition: width .3s;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 0 4px 1px rgba(17,31,45,0.3);
  .logo-and-btn {
    position: relative;
    height: 60px;
    .brand-logo {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: start;
      padding-left: 10px;
      .brand-logo-img {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
      .brand-logo-name {
        width: 122px;
        height: 36px;
        line-height: 36px;
        overflow: hidden;
        padding-left: 8px;
        font-size: 16px;
        font-weight: 700;
        opacity: 1;
        transition: all .3s;
      }
    }
    .fold-menu-btn { // 菜单收起按钮
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(100%, -50%);
      color: rgb(24, 23, 23);
      cursor: pointer;
      z-index: 900;
      .iwbfont {
        padding: 10px;
      }
    }
  }
  .menu-wrap{
    width: 100%;
    display: flex;
    overflow-y: hidden;
    overflow-x: hidden;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    // Fancy scroll
    .menu-ul {
      flex: 1;
      padding-top: 10px;
      z-index: auto;
      /deep/ .ivu-menu-item, /deep/ .ivu-menu-submenu-title {
        display: flex;
        align-items: center;
        background: white !important;
        // padding: 0;
        .iwbfont {
          transition: all .3s;
        }
      }
      /deep/ .ivu-menu .ivu-menu-item { // 不带图标的子菜单项
        height: 48px;
        justify-content: start;
        overflow: hidden;
        background: #1B1D24;
      }
      /deep/ .ivu-menu-submenu {
        position: relative;
      }

      .menu-item-icon {
        font-size: 18px;
      }
      .menu-item-text {
        overflow: hidden;
        opacity: 1;
        // width: 10rem;
        height: 25px;
        line-height: 25px;
        transition: all .3s;
        margin-left:4px;
        margin-right: 10px;
      }
      .sub-menu-item-text {
        max-width: 50px;
        text-overflow: ellipsis;

      }
      // /deep/ .ivu-menu-item-selected {
      //   color: #fff;
      //   background: @primary-color;
      // }
    }
    /deep/ .ivu-menu-submenu-title-icon { // 菜单箭头
      right: 12px;
      margin-right: 0;
      opacity: 1;
      transition: opacity .1s .3s, transform 0.2s; // 箭头本身带旋转
    }
  }
  .iwbfont {
    font-size: 22px;
  }
}
.menu-folded { // 收起菜单，覆盖部分样式
  width:60px;
  .menu-wrap{
    display: flex;
    .menu-ul {
      /deep/ .ivu-menu { // 子菜单样式
        right: 0;
        position: absolute;
        top: 0;
        width: 150px;
        transform: translate(100%, 0%);
        background-color: #363e4f;
        .ivu-menu-item {
          padding-left: 10px!important;
        }
      }
      .menu-item-text { // 隐藏菜单文字
        width: 0;
        opacity: 0;
      }
    }
    /deep/ .ivu-menu-submenu-title-icon { // 隐藏菜单箭头
      opacity: 0;
      transition: opacity .1s 0s;
    }
    /deep/ .ivu-menu-item > i {
      margin-right: 0;
    }
    /deep/ .ivu-menu-submenu {
      .iwbfont {
        margin-right: 0;
      }
    }
    .folder-menu-item-icon {
      font-size: 20px;
    }

  }
  .logo-and-btn .brand-logo .brand-logo-name { // 隐藏品牌文字
    width: 0;
    padding-left: 0;
    opacity: 0;
  }

  .logo-and-btn .brand-logo { // 隐藏的时候图标居中显示
    justify-content: center;
  }

  .menu-bottom-btn-wrap { // 隐藏的时候内容居中显示
    justify-content: center;
  }

  .logo-and-btn .fold-menu-btn { // 菜单收起时调转箭头方向
    transform: translate(100%, -50%) rotate(180deg);
  }
}

.bottom-wrap {
  height: 50px;
  border-top: #c0bdbd solid 1px;
  display: flex;
  padding: 10px;
}

.menu-bottom-btn-wrap {
  height: 50px;
  margin: 3px 6px;
  display: flex;
  flex-direction: row;
  padding: 10px 5px;
  border: 1px solid rgba(254, 161, 47, .05);
  background-color: rgba(254, 161, 47, .05);
  border-radius: 5px;
  transition: all .3s;
  cursor: pointer;
  align-items: center;
  .icon-img {
    font-size: 25px;
  }
  .text {
    font-weight: 700;
    font-size: 12px;
  }
  .sub-text {
    font-size: 12px;
    color: rgba(0, 0, 0, .45);
  }
  &:hover {
    background-color: #fea;
  }
  .btn-text-wrap {
    padding-left: 10px;
  }
}

.content-wrap {
  flex: 1 1 0%;
  overflow-y: auto;
  .meeting-scrollbox-vertical(3px);
}

/deep/ .main-side .menu-wrap .menu-ul .ivu-menu .ivu-menu-item {
  background: transparent !important;
}

.record {
  /deep/ .ivu-menu-item {
    padding-left: 33px !important;
    padding-right: 10px !important;
  }
}

.count-text {
  vertical-align: middle;
  height: 16px;
  border-radius: 10px;
  min-width: 16px;
  background: #FEA12E;
  border: 1px solid transparent;
  color: #fff;
  line-height: 16px;
  text-align: center;
  padding: 0 3px;
  font-size: 10px;
  white-space: nowrap;
  box-shadow: 0 0 0 1px #fff;
}

.sub-item-left-wrap {
  flex: 1;
  display: flex;
  align-items: center;
}

.sub-item-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .oprator-wrap {
    display: none;
  }
  &:hover{
    .oprator-wrap {
      display: block;
    }
  }
}
.oprator-icon {
  margin-right: 8px;
}
.user-dropdown-item {
  width: 150px;
  height: 40px;
}
</style>
