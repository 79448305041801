/*
 * @feature: 下载文件
 * @author: liuhua.chen 2023.03.29
 */
class DownloadFileWorker {
  constructor () {
    this.worker = this.initWorker()
    this.count = 0
  }

  createWorker (f) {
    const blob = new Blob(['(' + f.toString() + ')("' + window.location.origin + '")'])
    const url = window.URL.createObjectURL(blob)
    const worker = new Worker(url)
    return worker
  }

  /**
   * @description 初始化worker
   * @author liuhua.chen 2023.03.30
   */
  initWorker () {
    const worker = this.createWorker(() => {
      let count = 0

      // 监听主线程发送的消息,这个方法里面不能写await
      self.onmessage = e => {
        count += 1
        const { id, url, filename } = e.data

        // 下载文件
        getBlob(url).then((blob) => {
          count -= 1
          // 往主线程发送结果
          self.postMessage({ status: 'ok', blob, isAllFinish: count === 0, filename, id })
        }).catch(() => {
          count -= 1
          // 往主线程发送结果
          self.postMessage({ status: 'error', isAllFinish: count === 0, filename, id })
        })
      }

      /**
      * 获取 blob
      * url 目标文件地址
      */
      function getBlob (url) {
        return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest()
          xhr.open('GET', url, true)
          xhr.responseType = 'blob'

          // 下载完成
          xhr.onload = () => {
            if (xhr.status === 200) {
              resolve(xhr.response)
            } else {
              reject(new Error('error'))
            }
          }

          // 下载失败
          xhr.onerror = () => {
            reject(new Error('error'))
          }

          // 发送请求
          xhr.send()
        })
      }
    })
    return worker
  }

  /**
   * @description 下载文件
   * @param {*} url
   * @param {*} filename
   * @author liuhua.chen 2023.03.30
   */
  downloadFile (message) {
    // 往子线程发消息
    this.worker.postMessage(message)
    return this.worker
  }

  close () {
    if (this.worker) {
      this.worker.terminate()
    }
  }

  /**
   * 定义主线监听事件
   * @param {*} fun
   */
  onMessage (fun) {
    this.worker.onmessage = fun
  }
}

export default DownloadFileWorker
