<!--
 * @feature: 空数据
 * @author: liuhua.chen
 * @Date: 2024-11-05 15:38:43
-->
<template>
  <div class="empty-wrap">
    <Icon type="ios-folder-open-outline" size="40" />
    <span class="text">暂无数据</span>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data () {
    return {
    }
  },
  created () { },
  mounted () { },
  updated () { },
  unmounted () { },
  methods: {},
  computed: {},
  watch: {}
}
</script>
<style lang="less" scoped>
.empty-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: inherit;
  height: inherit;
  color: gray;

  .text {
    margin-top: 10px;
  }
}
</style>
