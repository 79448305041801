/*
 * @feature:
 * @author: liuhua.chen
 * @Date: 2024-11-01 15:16:31
 */
import { createI18n } from 'vue-i18n'
import en from 'view-ui-plus/dist/locale/en-US'
import zh from 'view-ui-plus/dist/locale/zh-CN'
import zhTW from 'view-ui-plus/dist/locale/zh-TW'

export const i18n = createI18n({
  locale: 'zh-CN',
  fallbackLocale: 'zh-CN',
  messages: {
    'zh-CN': Object.assign(require('@/lang/zh-CN'), zh),
    en: Object.assign(require('@/lang/en'), en),
    'zh-TW': Object.assign(require('@/lang/zh-TW'), zhTW)
  },
  silentTranslationWarn: true
})

const loadedLanguages = ['en', 'zh-CN', 'zh-TW'] /* default language */
const setingLanguages = ['en', 'zh-CN', 'zh-TW']

export function setI18nLanguage (lang) {
  i18n.global.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync (lang) {
  if (i18n.global.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      if (setingLanguages.includes(lang)) {
        return import('@/lang/zh-CN').then(msgs => {
          i18n.setLocaleMessage(lang, Object.assign(msgs, lang))
          loadedLanguages.push(lang)
          return setI18nLanguage(lang)
        })
      } else {
        return Promise.resolve(lang)
      }
    }
    return Promise.resolve(setI18nLanguage(lang))
  }
  return Promise.resolve(lang)
}
