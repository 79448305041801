/*
 * @feature: 用户信息接口
 * @author: liuhua.chen
 * @Date: 2024-12-05 10:46:46
 */
import request from '@/utils/request'

// 获取用户信息
export function getUserInfo () {
  return request({
    url: '/uuc/user/c/session',
    method: 'post'
  })
}

// 编辑用户信息
export function editUserInfo ({ avatar, nickname, sex, birthday }) {
  return request({
    url: '/uuc/user/c/edit',
    method: 'post',
    data: { avatar, nickname, sex, birthday }
  })
}
