<!--
 * @feature: 统一布局的抽屉
 * @author: liuhua.chen
 * @Date: 2024-11-07 16:52:10
-->
<template>
  <Drawer v-model="model" :width="width" :title="title" :mask-closable="maskClosable" class-name="custom-drawer">
    <div class="draw-wrap">
      <!-- 内容部分 -->
      <div class="content">
        <slot></slot>
      </div>
      <div v-if="showBottom" class="bottom-wrap">
        <Button class="bottom-btn" type="primary" :loading="okLoading" @click="doOkBtnClick">{{ okText ||
          $t('common.sure') }}</Button>
        <Button v-if="showCancel" class="bottom-btn" @click="doCancelBtnClick">{{ cancelText || $t('common.cancel')
          }}</Button>

      </div>
    </div>
  </Drawer>
</template>

<script setup>
import { defineEmits, defineModel, defineProps } from 'vue'
const model = defineModel()

const prop = defineProps({
  width: {
    type: [Number, String],
    default: 450
  },
  showBottom: { // 是否显示底部按钮
    type: Boolean,
    default: true
  },
  title: String,
  closable: { // 是否显示右上角的关闭按钮
    type: Boolean,
    default: true
  },
  maskClosable: { // 是否允许点击遮罩层关闭
    type: Boolean,
    default: true
  },
  okLoading: Boolean,
  okText: String,
  cancelText: String,
  showCancel: {
    type: Boolean,
    default: true
  },
  isCancelClose: { // 点击取消是否关闭
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['on-ok', 'on-cancel', 'update:modelValue'])

function doCancelBtnClick () {
  if (prop.isCancelClose) {
    emit('update:modelValue', false)
  }
  emit('on-cancel')
}

function doOkBtnClick () {
  emit('on-ok')
}
</script>

<style scoped lang='less'>

@import "~@/styles/mixins.less";

.draw-wrap {
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.title-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  border-bottom: @divideColor;
  width: 100%;

  .title-text {
    margin-left: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
}

.bottom-wrap {
  padding: 10px 24px;
  border-top: @divideColor;

  .bottom-btn {
    margin-right: 15px;
  }
}

.content {
  width: 100%;
  flex: 1;
  overflow-y: auto;
  .meeting-scrollbox-vertical(0.5rem);
}

.close-btn {
  cursor: pointer;
  margin-right: 10px;
}
</style>
<style>
.custom-drawer .ivu-drawer-body  {
  padding: 0px;
}
.custom-drawer .ivu-drawer-header-inner {
  font-weight: bold;
}
</style>
