<!--
 * @feature: 编辑信息的body，有两个地方用到，一个是modal,一个是drawer
 * @author: liuhua.chen
 * @Date: 2024-11-25 14:48:32
-->
<template>
  <div class="content-wrap">
    <Form ref="editForm" :model="form" label-position="top" :rules="ruleValidate">
      <FormItem :label="$t('file.meetingname')" prop="fileTitle">
        <Input v-model="form.fileTitle" :placeholder="$t('file.editPlaceHolder')" clearable></Input>
      </FormItem>
      <FormItem :label="$t('file.meetingPersonel')">
        <Input v-model="form.personnel" placeholder="请输入与会人员" clearable></Input>
      </FormItem>
      <FormItem :label="$t('file.meetingAdress')">
        <Input v-model="form.address" placeholder="请输入会议地点" clearable></Input>
      </FormItem>
    </Form>
  </div>
</template>

<script setup>
import { ref, reactive, defineProps, getCurrentInstance, defineEmits, defineExpose, watch } from 'vue'
import { editFileInfo } from '@/api/folder'

const _this = getCurrentInstance().appContext.config.globalProperties

const props = defineProps({
  fileInfo: Object
})

const emit = defineEmits(['on-success', 'on-commit-start', 'on-finish'])

const ruleValidate = {
  fileTitle: [{
    required: true, message: _this.$t('file.required')
  }]
}

const form = reactive({
  fileTitle: '',
  personnel: '',
  address: ''
})

const editForm = ref(null)

/**
     * @description: 重置数据
     * @author: liuhua.chen
     * @Date: 2024-11-08 09:53:45
     */
function resetData () {
  editForm.value?.resetFields()
  Object.assign(form, props.fileInfo)
}

/**
     * @description: 修改信息
     * @author: liuhua.chen
     * @Date: 2024-11-08 10:03:41
     */
function onConfirm () {
  editForm.value.validate((valid) => {
    if (valid) {
      emit('on-commit-start')

      editFileInfo({ fileCode: props.fileInfo.fileCode, targetName: form.fileTitle, personnel: form.personnel, address: form.address }).then(() => {
        _this.$Message.success(_this.$t('common.success'))
        emit('on-success')
      }).finally(() => {
        emit('on-finish')
      })
    }
  })
}

defineExpose({
  onConfirm,
  resetData
})

watch(() => props.fileInfo, () => {
  resetData()
}, { immediate: true })
</script>

<style scoped lang='less'>
.title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 10px;
}

.content-wrap {
  padding: 30px;
}
</style>
