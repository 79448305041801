/*
 * @feature:
 * @author: liuhua.chen
 * @Date: 2024-11-01 15:16:31
 */
module.exports = {
  appName: '飞利浦声文',
  common: {
    loading: '載入中...',
    success: '操作成功',
    sure: '確定',
    cancel: '取消',
    refresh: '刷新',
    delete: '刪除',
    copy: '複製',
    edit: '编辑'
  },
  navigator: {
    home: '首頁',
    recycle: '回收站',
    pay: '儲值購買',
    cooperation: '商務合作',
    message: '訊息通知',
    useTime: '可用時長',
    callTime: '通話時間 {0}',
    dateLine: '{0} 到期',
    record: '錄音清單',
    allFolder: '所有文件',
    starFolder: '星標檔案'
  },
  login: {
    title: '使用者登入',
    smsTitle: '驗證碼登入',
    codeTitle: '密碼登入',
    phoneHint: '請輸入您的手機號碼',
    codeHint: '請輸入驗證碼',
    codebtn: '取得驗證碼',
    second: '秒',
    privacyTitle: '登入您的帳戶即表示您同意',
    userPrivacy: '《使用者協議》',
    and: '和',
    privacy: '《隱私權政策》',
    login: '登入',
    phoneError: '請輸入正確的手機號碼',
    checkPrivacy: '請勾選協議',
    sendSuccess: '發送成功',
    nologinId: '請輸入帳號',
    nopassword: '請輸入密碼'
  },
  file: {
    dialog: {
      creatTitle: '新資料夾',
      renameTitle: '重新命名',
      inputHint: '請輸入資料夾名稱',
      subtitle: '資料夾名稱',
      deleteTitle: '刪除 {name} 資料夾',
      deleteContent: '確定刪除「{name}」？音訊檔案將移至「全部檔案」'
    },
    batchMove: '批次移入',
    batchStar: '大量收藏',
    batchUnstar: '大量解除收藏',
    batchDelete: '批次刪除',
    selectAll: '全選',
    deleteContent: '該檔案將移入雲端回收站，15天後自動刪除',
    deleteFile: '刪除檔案',
    moveFile: '移入資料夾',
    refreshList: '刷新清單',
    sureMove: '確認移動',
    mulSelect: '多選',
    showTranslate: '顯示翻譯',
    exportOri: '導出原文',
    exportMusic: '匯出音訊',
    editInfo: '修改訊息',
    batchAddAbstact: '批次新增摘要',
    addAbstract: '新增摘要',
    addPic: '新增圖片',
    abstract: '摘要',
    abstractTime: '摘要時長',
    translate: '翻譯語言',
    deleteFileContent: '是否刪除 {name} 檔案',
    submit: '提交',
    reset: '重置',
    meetingname: '會議名稱',
    meetingPersonel: '與會人',
    meetingAdress: '會議地點',
    editPlaceHolder: '請輸入',
    required: '必填',
    hasAbstract: '已經加入摘要',
    copySuccess: '複製成功',
    deleteAbstractContent: '刪除這段摘要內容',
    save: '儲存',
    saveContent: '儲存 (Ctrl / ⌘ + Enter)',
    share: {
      audioTitle: '分享音訊連結',
      digestTitle: '分享摘要連結',
      top1: '分享連結及提取碼已產生',
      top2: '透過QQ、微信、微博等分享給好友吧',
      link: '連結',
      code: '提取碼',
      codeBtn: '複製連結及提取碼',
      qrHint: '微信掃一掃，進行分享',
      encrypt: '加密',
      yes: '是',
      no: '否',
      once: '閱後即焚',
      dateTime: '有效期限',
      day: '{day}天',
      isSave: '是否儲存',
      save: '允許保存',
      nosave: '不可儲存',
      getLinkBtn: '生成連結自動複製',
      copyContent: '{username}分享的錄音檔案：{title} \n連結: {url}\n提取碼: {checkCode}',
      aiTitle: 'AI摘要',
      finish: '完成',
      aiHint: 'AI生成，僅供參考'
    },
    upload: {
      formatError: '文件格式有誤',
      error: '上傳失敗',
      downError: '檔案下載失敗',
      export: '導出中...'
    },
    shareDetail: {
      subTitle: '簡單、純粹、有效率的AI雲端筆記軟體',
      visite: '訪問官網',
      download: '立即下載',
      shareText: '輸入分享碼，存取分享內容',
      codeBtn: '點擊輸入',
      save: '保存到飛利浦聲文',
      codeHint: '請輸入分享碼',
      accountHint: '輸入您的手機號碼或信箱',
      smsCodeHint: '請輸入驗證碼',
      saveHint: '儲存到小鹿聲文即表示您已同意',
      accountError: '帳號格式錯誤',
      success: '轉存成功',
      successHint: '檔案已儲存至「同步檔案」',
      go: '請在飛利浦聲文 App 中查看'
    }
  },
  home: {
    openHint: '享 10+ 會員權益，低至 0.5 元/時',
    open: '去開通',
    recentRecord: '最近錄音',
    upload: '上傳錄音',
    audioWrite: '聲文速記',
    audioTrans: '同聲傳譯',
    reWrite: '重新轉寫',
    reSetting: '轉寫設定',
    reLang: '轉寫語言',
    editInfo: '編輯基礎資料',
    uploadFile: '上傳錄音檔',
    uploadHint: '點選或拖曳檔案到此區域進行上傳',
    next: '下一步',
    preview: '上一步',
    privacy1: '我已閱讀且同意',
    privacy2: '並對我上傳文件的合法合規性負責',
    recogiz: '辨識語言',
    selectFileHint: '請選擇音訊',
    privacyHint: '請先閱讀並勾選同意《使用者協議》和《隱私權政策》',
    selectLang: '選擇語言',
    syncBtn: '待同步音訊',
    syncHint: '您有{count}條同步失敗的記錄，點擊繼續同步',
    syncModalTitle: '{count}檔案等待同步',
    sync: '同步',
    download: '下載'
  },
  tranlateLang: {
    zh: '中文（簡體）',
    zh_tw: '中文（繁體）',
    en: '英語',
    japanese: '日文',
    german: '德語',
    french: '法語',
    korean: '韓語',
    russian: '俄文',
    panish: '西班牙文',
    italian: '義大利語',
    cantonese: '粵語',
    thai: '泰語',
    indonesian: '印尼語',
    malay: '馬來西亞語',
    filipino: '菲律賓語',
    arabic: '阿拉伯語'
  },
  live: {
    back: '返回',
    resume: '恢復錄音',
    pause: '暫停錄音',
    stop: '結束錄音',
    record: '錄音',
    notautorecord: '介面未有任何互動，請手動點擊開始錄音',
    networkLow: '網絡資訊弱',
    neterr: '網絡异常',
    short: '錄音時間太短',
    saveErr: '保存失敗',
    socketErr: '語音辨識异常，錄音持續中',
    socketErrTitle: '語音辨識异常',
    socketErrContent: '當前實时語音辨識异常，您可在錄音結束後查看識別內容'
  }
}
