<!--
 * @feature: 删除文件夹
 * @author: liuhua.chen
 * @Date: 2024-11-05 09:50:49
-->
<template>
  <ConfirmModal v-model="model" :title="$t('file.dialog.deleteTitle', { name: props.fileInfo.folderName })" @on-ok="onSubmit"
    :ok-loading="okLoading">
    <div>
      {{ $t('file.dialog.deleteContent', { name: props.fileInfo.folderName }) }}
    </div>
  </ConfirmModal>

</template>

<script setup>
import { defineProps, ref, getCurrentInstance, defineEmits, defineModel } from 'vue'
import { deleteFolder } from '@/api/folder'
import ConfirmModal from '@/components/common/confirm-modal.vue'

const _this = getCurrentInstance().appContext.config.globalProperties

const model = defineModel()

const okLoading = ref(false)

const props = defineProps({
  fileInfo: Object
})

const emit = defineEmits(['on-success', 'update:modelValue'])

/**
     * @description: 删除文件夹
     * @author: liuhua.chen
     * @Date: 2024-11-05 10:11:50
     */
function onSubmit () {
  okLoading.value = true
  deleteFolder(props.fileInfo.folderCode).then(() => {
    // 删除成功
    _this.$Message.success(_this.$t('common.success'))
    emit('on-success')
    emit('update:modelValue', false)
  }).finally(() => { okLoading.value = false })
}

</script>
