/*
 * @feature: 用户信息
 * @author: liuhua.chen
 * @Date: 2024-11-04 11:15:11
 */
import Lockr from 'lockr'

const user = {
  state: {
    authorization: '',
    userInfo: {}
  },
  mutations: {
    setAuthorization (state, token) {
      const tokenStr = token || ''
      state.authorization = tokenStr
      Lockr.set('authorization', tokenStr)
    },
    logout (state, vm) {
      const lockKeys = Lockr.keys() // lockr所有key
      const notRemoveKeys = [] // 不需要清空的
      // 清空本地保存的信息
      lockKeys.forEach((key) => {
        if (notRemoveKeys.indexOf(key) === -1) Lockr.rm(key)
      })
      if (window.router.currentRoute.value.path !== '/login') {
        window.router.replace('/login')
      }
    },
    setUserInfo (state, data) {
      state.userInfo = data
      Lockr.set('userInfo', data)
    }
  },
  actions: {
    setAuthorization ({ commit }, token) {
      commit('setAuthorization', token)
    },
    logout ({ commit }) {
      commit('logout')
    },
    setUserInfo ({ commit }, data) {
      commit('setUserInfo', data)
    }
  }
}

export default user
