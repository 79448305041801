<!--
 * @feature:
 * @author: liuhua.chen
 * @Date: 2024-11-12 15:57:45
-->
<template>
   <div id="app" ref="app">
    <router-view/>
    <Spin class="app-loading" v-show="canShowLoading" fix>
      <Icon type="ios-loading" size="32" class="spin-icon-load"></Icon>
      <div class="loading-txt">{{$t('common.loading')}}</div>
    </Spin>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import store from './store'
import { i18n } from './lang'

/**
* 是否显示Loading
* @return {Boolen} true 显示, false 不显示
* @author aaron.wei 2020.03.05
*/
const canShowLoading = computed(() => {
  return store.state.globalLoading
})

onMounted(() => {
  i18n.locale = 'zh-Cn'
})

</script>

<style lang="less">
  .spin-icon-load{
    animation: ani-spin-loop 1s linear infinite;
  }
  @keyframes ani-spin-loop {
    from { transform: rotate(0deg);}
    50%  { transform: rotate(180deg);}
    to   { transform: rotate(360deg);}
  }
  .app-loading {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 99999;
    position: fixed;
  }
  .loading-txt {
    font-size: 16PX;
  }
</style>
