<!--
 * @feature: 删除文件
 * @author: liuhua.chen
 * @Date: 2024-11-07 16:12:55
-->
<template>
  <ConfirmModal v-model="model" :ok-loading="loading" @on-ok="onSubmit">
    <div>
      <Icon type="md-alert" class="icon" />
      {{ $t('file.deleteFileContent', { name: pros.fileTitle }) }}
    </div>

  </ConfirmModal>
</template>

<script setup>
import { deleteFile } from '@/api/folder'
import { defineProps, ref, defineEmits, defineModel } from 'vue'
import ConfirmModal from '@/components/common/confirm-modal.vue'

const model = defineModel()

const emit = defineEmits(['on-success', 'update:modelValue'])

const pros = defineProps({
  fileCode: {
    type: String
  },
  fileTitle: {
    type: String
  }
})

const loading = ref(false)

function onSubmit () {
  loading.value = true
  deleteFile(pros.fileCode).then(() => {
    emit('update:modelValue', false)
    emit('on-success')
  }).finally(() => { loading.value = false })
}

</script>
<style lang="less" scoped>
@import '~@/styles/index.less';

.icon {
  color: @primary-color;
  font-size: 25px;
}
</style>
