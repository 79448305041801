<!--
 * @feature: 确认对话框
 * @author: liuhua.chen
 * @Date: 2024-11-26 09:36:56
-->
<template>
  <Modal v-model="model" :width="width" footer-hide :style="{padding: '0 0 24px'}" :closable="closable">
    <div class="iwb-modal-confirm">
      <div v-if="title !== ''" class="iwb-modal-confirm-head">
        <div class="iwb-modal-confirm-head-title">{{ title }}</div>
      </div>
      <div class="iwb-modal-confirm-body">
        <slot></slot>
      </div>
      <div v-if="showCancel" class="iwb-modal-confirm-footer">
        <Button class="iwb-modal-btn iwb-modal-cancel" @click="doCancelBtnClick" :loading="cancelLoading"
          :disabled="cancelDisabled">{{ cancelText || $t('common.cancel') }}</Button>
        <Button class="iwb-modal-btn iwb-modal-submit" type="primary" :loading="okLoading" @click="doOkBtnClick"
          :disabled="okDisabled">{{ okText || $t('common.sure') }}</Button>
      </div>
      <div v-else class="iwb-modal-confirm-footer footer-single-btn">
        <Button class="iwb-modal-btn iwb-modal-submit" type="primary" :loading="okLoading" @click="doOkBtnClick"
          :disabled="okDisabled">{{ okText || $t('common.sure') }}</Button>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import { defineEmits, defineModel, defineProps } from 'vue'

const model = defineModel()

defineProps({
  width: {
    type: [Number, String],
    default: 520
  },
  title: String,
  cancelLoading: Boolean,
  cancelDisabled: {
    type: Boolean,
    default: false
  },
  okDisabled: Boolean,
  okLoading: Boolean,
  okText: String,
  cancelText: String,
  showCancel: {
    type: Boolean,
    default: true
  },
  closable: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['on-ok', 'on-cancel', 'update:modelValue'])

function doOkBtnClick () {
  emit('on-ok')
}

function doCancelBtnClick () {
  emit('on-cancel')
  emit('update:modelValue', false)
}

</script>

<style scoped lang='less'>
 .iwb-modal-confirm-head {
    text-align: left;
    .iwb-modal-confirm-head-title {
      color: #1d2129;
      font-size: 18px;
      text-align: left;
      font-weight: 400;
    }
  }
  .iwb-modal-confirm-body {
    text-align: left;
    padding: 24px 0;
    font-size: 14px;
    line-height: 1.5;
  }
  .iwb-modal-confirm-js-body{
    word-break: break-all;
    min-height: 90px;
  }
  .iwb-modal-confirm-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .iwb-modal-btn {
      padding: 0 20px;
    }
    button {
      border-radius: 4px;
      font-size: 14px;
      & + button {
        margin-left: 16px;
        margin-bottom: 0;
      }
    }
  }
.footer-single-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
  margin-top: 8px;
}
</style>
