<!--
 * @feature: 语言选择按钮
 * @author: liuhua.chen
 * @Date: 2024-11-04 09:16:54
-->
<template>
  <Dropdown class="dropdown-wrap" @on-click="chageLang">
    <Icon type="ios-appstore-outline" size="22"/>
      <!-- <DropdownItem name="en">us English</DropdownItem>
      <DropdownItem name="jp">jp 日本语</DropdownItem>
      <DropdownItem name="cn">cn 简体中文</DropdownItem>
      <DropdownItem name="hk">hk 繁体中文</DropdownItem> -->
      <template #list>
        <DropdownMenu>
          <DropdownItem v-for="(item, index) in dropdownItem" :key="index" :name="item.name" :selected="item.name === currentlang">{{item.label}}</DropdownItem>
        </DropdownMenu>
      </template>
  </Dropdown>
</template>

<script setup>
import { setI18nLanguage, i18n } from '@/lang'
import store from '@/store'
import { onMounted, ref } from 'vue'

const currentlang = ref('')

const dropdownItem = [
  {
    name: 'zh-CN',
    label: 'cn 简体中文'
  },
  {
    name: 'zh-TW',
    label: 'hk 繁体中文'
  },
  {
    name: 'en',
    label: 'us English'
  }
]

function chageLang (lang) {
  if (!lang) return
  if (i18n.global.locale === lang.toLowerCase()) return
  store.commit('setLang', lang)
  setI18nLanguage(lang)
  currentlang.value = lang
  // Util.setTitle(this.$t(this.$route.query?.wbName || this.$route.meta?.title))
}

onMounted(() => {
  currentlang.value = i18n.global.locale
})

</script>
<style lang="less" scoped>
.dropdown-wrap {
  cursor: pointer;
  padding: 5px;
}
</style>
