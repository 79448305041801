/*
 * @feature:
 * @author: liuhua.chen
 * @Date: 2024-11-01 15:16:31
 */
module.exports = {
  appName: '飞利浦声文',
  common: {
    loading: 'Loading...',
    success: 'Operation successful',
    sure: 'Confirm',
    cancel: 'Cancel',
    refresh: 'Refresh',
    delete: 'Delete',
    copy: 'Copy',
    edit: 'Edit'
  },
  navigator: {
    home: 'Home',
    recycle: 'Recycle Bin',
    pay: 'Recharge Purchase',
    cooperation: 'Business Cooperation',
    message: 'Message Notification',
    useTime: 'Available Time',
    callTime: 'Call Time {0}',
    dateLine: '{0} Expiration',
    record: 'Recording List',
    allFolder: 'All Files',
    starFolder: 'Starred Files'
  },
  login: {
    title: 'User Login',
    smsTitle: 'Verification Code Login',
    codeTitle: 'Password Login',
    phoneHint: 'Please enter your mobile number',
    codeHint: 'Please enter the verification code',
    codebtn: 'Get the verification code',
    second: 'Seconds',
    privacyTitle: 'By logging into your account, you agree',
    userPrivacy: 'User Agreement',
    and: '和',
    privacy: 'Privacy Policy',
    login: 'Login',
    phoneError: 'Please enter a valid mobile number',
    checkPrivacy: 'Please check the agreement',
    sendSuccess: 'Sent successfully',
    nologinId: 'Please enter your account number',
    nopassword: 'Please enter your password'
  },
  file: {
    dialog: {
      creatTitle: 'Create a new folder',
      renameTitle: 'Rename',
      inputHint: 'Please enter a folder name',
      subtitle: 'Folder name',
      deleteTitle: 'Delete {name} folder',
      deleteContent: 'Are you sure you want to delete "{name}"? The audio file will be moved to "All Files"'
    },
    batchMove: 'Batch move in',
    batchStar: 'Batch collection',
    batchUnstar: 'Batch uncollect',
    batchDelete: 'Batch delete',
    selectAll: 'Select all',
    deleteContent: 'The file will be moved to the cloud recycle bin and automatically deleted after 15 days',
    deleteFile: 'Delete file',
    moveFile: 'Move to folder',
    refreshList: 'Refresh list',
    sureMove: 'Confirm move',
    mulSelect: 'Multiple selections',
    showTranslate: 'Show translation',
    exportOri: 'Export original text',
    exportMusic: 'Export audio',
    editInfo: 'Modify information',
    batchAddAbstact: 'Batch add abstracts',
    addAbstract: 'Add abstracts',
    addPic: 'Add pictures',
    abstract: 'Abstract',
    abstractTime: 'Summary duration',
    translate: 'Translation language',
    deleteFileContent: 'Delete {name} file',
    submit: 'Submit',
    reset: 'Reset',
    meetingname: 'Meeting name',
    meetingPersonel: 'Participants',
    meetingAdress: 'Meeting location',
    editPlaceHolder: 'Please enter',
    required: 'Required',
    hasAbstract: 'Already added to the abstract',
    copySuccess: 'Copy successfully',
    deleteAbstractContent: 'Delete this abstract content',
    save: 'Save',
    saveContent: 'Save (Ctrl / ⌘ + Enter)',
    share: {
      audioTitle: 'Share audio link',
      digestTitle: 'Share summary link',
      top1: 'Share link and extraction code have been generated',
      top2: 'Share with friends via QQ, WeChat, Weibo, etc.',
      link: 'Link',
      code: 'Extraction code',
      codeBtn: 'Copy link and extraction code',
      qrHint: 'WeChat scan and share',
      encrypt: 'Encrypt',
      yes: 'Yes',
      no: 'No',
      once: 'Burn after reading',
      dateTime: 'Validity period',
      day: '{day} days',
      isSave: 'Save',
      save: 'Allow saving',
      nosave: 'Cannot save',
      getLinkBtn: 'Generate link and automatically copy',
      copyContent: '{username} shared recording file: {title} \nLink: {url}\nExtraction code: {checkCode}',
      aiTitle: 'AI Summary',
      finish: 'Complete',
      aiHint: 'AI generated, for reference only'
    },
    upload: {
      formatError: 'File format is incorrect',
      error: 'Upload failed',
      downError: 'File download failed',
      export: 'Exporting...'
    },
    shareDetail: {
      subTitle: 'Simple, pure and efficient AI cloud note taking software',
      visite: 'Visit the official website',
      download: 'Download now',
      shareText: 'Enter the sharing code to access the shared content',
      codeBtn: 'Click to enter',
      save: 'Save to Philips SoundText',
      codeHint: 'Please enter the sharing code',
      accountHint: 'Enter your mobile phone number or email',
      smsCodeHint: 'Please enter the verification code',
      saveHint: 'Save to Xiaolu SoundText means you have agreed',
      accountError: 'Account format error',
      success: 'Successful transfer',
      successHint: 'The file has been saved to "Synchronized Files"',
      go: 'Please view it in the Philips Sound Text App'
    }
  },
  home: {
    openHint: 'Enjoy 10+ membership benefits, as low as 0.5 yuan/hour',
    open: 'Go to activate',
    recentRecord: 'Recent recording',
    upload: 'Upload',
    audioWrite: 'Transcript',
    audioTrans: 'Simultaneous interpretation',
    reWrite: 'Re-transcribe',
    reSetting: 'Translation settings',
    reLang: 'Translation language',
    editInfo: 'Edit basic information',
    uploadFile: 'Upload recording file',
    uploadHint: 'Click or drag the file to this area to upload',
    next: 'Next',
    preview: 'Previous',
    privacy1: 'I have read and agreed',
    privacy2: 'And I am responsible for the legality and compliance of the files I upload',
    recogiz: 'Identify language',
    selectFileHint: 'Please select audio',
    privacyHint: 'Please read and tick the "User Agreement" and "Privacy Policy" first',
    selectLang: 'Select language',
    syncBtn: 'Audio to be synced',
    syncHint: 'You have {count} records of failed syncs, click to continue syncing',
    syncModalTitle: '{count} files to be synced',
    sync: 'Synchronize',
    download: 'Download'
  },
  tranlateLang: {
    zh: 'Chinese (Simplified)',
    zh_tw: 'Chinese (Traditional)',
    en: 'English',
    japanese: 'Japanese',
    german: 'German',
    french: 'French',
    korean: 'Korean',
    russian: 'Russian',
    panish: 'Spanish',
    italian: 'Italian',
    cantonese: 'Cantonese',
    thai: 'Thai',
    indonesian: 'Indonesian',
    malay: 'Malaysian',
    filipino: 'Filipino',
    arabic: 'Arabic'
  },
  live: {
    back: 'Return',
    resume: 'Resume recording',
    pause: 'Pause recording',
    stop: 'End recording',
    record: 'Recording',
    notautorecord: 'There is no interaction on the interface, please click to start recording manually',
    networkLow: 'Network information is weak',
    neterr: 'Network abnormality',
    short: 'Recording time is too short',
    saveErr: 'Save failed',
    socketErr: 'Speech recognition abnormality, recording continues',
    socketErrTitle: 'Speech recognition abnormality',
    socketErrContent: 'Current real-time speech recognition is abnormal, you can view the recognition content after the recording ends'
  }
}
