/*
 * @feature: 文件功能api
 * @author: liuhua.chen
 * @Date: 2024-11-04 14:47:26
 */
import request from '@/utils/request'

// 获取文件夹列表
export function getFolderList () {
  return request({
    method: 'post',
    url: '/uuc/res/folder/list-web'
  })
}

// 创建文件夹
export function newFolder ({ targetName, sort, color }) {
  return request({
    method: 'post',
    url: '/uuc/res/folder/add',
    data: { targetName, sort, color }
  })
}

// 创建文件夹
export function renameFolder ({ targetName, folderCode }) {
  return request({
    method: 'post',
    url: '/uuc/res/folder/rename',
    data: { targetName, folderCode }
  })
}

// 删除文件夹
export function deleteFolder (folderCode) {
  const data = Array.isArray(folderCode) ? folderCode : [folderCode]

  return request({
    method: 'post',
    url: '/uuc/res/folder/delete',
    data: { folderCodes: data }
  })
}

// 获取文件夹下的文件列表
export function getList ({ folderCode, searchKey, current = 2, size = 10 }) {
  return request({
    method: 'post',
    url: '/uuc/res/file/page',
    data: { folderCode, searchKey, current, size }
  })
}

// 文件收藏
export function star (fileCode) {
  const data = Array.isArray(fileCode) ? fileCode : [fileCode]

  return request({
    method: 'post',
    url: '/uuc/res/file/star',
    data: { fileCodes: data }
  })
}
// 文件取消收藏
export function unstar (fileCode) {
  const data = Array.isArray(fileCode) ? fileCode : [fileCode]

  return request({
    method: 'post',
    url: '/uuc/res/file/unstar',
    data: { fileCodes: data }
  })
}

// 删除文件
export function deleteFile (fileCode) {
  const data = Array.isArray(fileCode) ? fileCode : [fileCode]

  return request({
    method: 'post',
    url: '/uuc/res/file/delete',
    data: { fileCodes: data }
  })
}

// 移动文件
export function moveFile ({ fileCodes, targetFolderCode }) {
  const data = Array.isArray(fileCodes) ? fileCodes : [fileCodes]

  return request({
    method: 'post',
    url: '/uuc/res/file/shift-in',
    data: { fileCodes: data, targetFolderCode }
  })
}

// 文件详情
export function getFileInfo (fileCode) {
  return request({
    method: 'post',
    url: '/uuc/res/file/detail',
    data: { fileCode, pcWeb: true }
  })
}

// 文件修改信息
export function editFileInfo ({ fileCode, targetName, personnel, address }) {
  return request({
    method: 'post',
    url: '/uuc/res/file/edit',
    data: { fileCode, targetName, personnel, address }
  })
}

// 获取文件摘要
export function getFileAiDigest (fileCode) {
  return request({
    method: 'post',
    url: '/uuc/res/file/digest/detail',
    data: { fileCode }
  })
}

// 添加摘要
export function addDigest ({ fileCode, itemTimeDigests }) {
  return request({
    method: 'post',
    url: '/uuc/res/file/content-item/digest/add',
    data: { fileCode, itemTimeDigests }
  })
}

// 删除摘要
export function deleteDigest ({ fileCode, itemTimes }) {
  const data = Array.isArray(itemTimes) ? itemTimes : [itemTimes]
  return request({
    method: 'post',
    url: '/uuc/res/file/content-item/digest/delete',
    data: { fileCode, itemTimes: data }
  })
}

// 编辑摘要、内容项更新
export function updateContentItem ({ fileCode, firstLanguage, secondLanguage, fileItem }) {
  return request({
    method: 'post',
    url: '/uuc/res/file/content-item/update',
    data: { fileCode, firstLanguage, secondLanguage, fileItem }
  })
}

// 内容项添加图片
export function addImg ({ fileCode, itemTime, images }) {
  return request({
    method: 'post',
    url: '/uuc/res/file/content-item/image/add',
    data: { fileCode, itemTime, images }
  })
}

// 内容项添加图片
export function deleteImg ({ fileCode, itemTime, imageId }) {
  return request({
    method: 'post',
    url: '/uuc/res/file/content-item/image/delete',
    data: { fileCode, itemTime, imageId }
  })
}

// 获取costoken
export function getCos () {
  return request({
    method: 'post',
    url: '/uaa/cos/tk'
  })
}

// 分享文件
export function shareFile ({ fileCode, itemTimes, effectiveDays, encrypt, once, save }) {
  return request({
    method: 'post',
    url: '/uuc/res/file/share/url',
    data: { fileCode, itemTimes, effectiveDays, encrypt, once, save }
  })
}

// 获取ai摘要
export function getAiDigest (fileCode) {
  return request({
    method: 'post',
    url: '/uuc/res/file/digest/detail',
    data: { fileCode }
  })
}

// 添加AI摘要
export function addAiDigest ({ fileCode, digest, digestCount, type, reference }) {
  return request({
    method: 'post',
    url: '/uuc/res/file/digest/edit',
    data: { fileCode, digest, digestCount, type, reference }
  })
}

// 获取分享的文档
export function getShareFile ({ sn, checkCode }) {
  return request({
    method: 'post',
    url: '/uuc/res/file/share/detail',
    data: { sn, checkCode }
  })
}

// 保存分享的文档
export function saveShareFile ({ sn, checkCode, fileCode, token }) {
  return request({
    method: 'post',
    url: '/uuc/res/file/share/save',
    headers: {
      token
    },
    data: { sn, checkCode, fileCode }
  })
}
