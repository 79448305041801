<!--
 * @feature: 首页界面
 * @author: liuhua.chen
 * @Date: 2024-11-01 09:11:15
-->
<template>
  <div class="main-page">
    <side-menu v-model:sideMenuFolded="sideMenuFolded"></side-menu>
    <div class="main">
      <RouterView />
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue'
import SideMenu from './side-menu.vue'
import { RouterView } from 'vue-router'
import { getUserInfo } from '@/api/user'
import EventBus from '@/EventBus'
import store from '@/store'
const sideMenuFolded = ref(false)

onMounted(() => {
  doGetUserInfo()
  EventBus.on('user-info-change', doGetUserInfo)
})

function doGetUserInfo () {
  getUserInfo().then((res) => {
    if (res.data) {
      store.commit('setUserInfo', res.data)
    }
  })
}

onUnmounted(() => {
  EventBus.off('user-info-change', doGetUserInfo)
})

</script>
<style lang="less" scoped>
@import '~@/styles/mixins.less';
.main-page {
  display: flex;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  .side-menu-folded {
    width: calc(100% - @foldedMenuWidth);
  }
  .main {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: #f0f2f5;
  }
}
</style>
