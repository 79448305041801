<!--
 * @feature: 文件夹创建或重名
 * @author: liuhua.chen
 * @Date: 2024-11-05 08:24:02
-->
<template>
  <ConfirmModal v-model="model" width="400" :title="state.title" :ok-disabled="state.targetName === ''" :ok-loading="state.loading"  @on-ok="onSubmit">
    <div>
      <div class="title-wrap">
        <span class="span-text">*</span> {{ $t('file.dialog.subtitle') }}
      </div>
      <Input class="form-inpt" ref="rename" v-model="state.targetName" :placeholder="$t('file.dialog.inputHint')"
        clearable size="large" />
    </div>
  </ConfirmModal>
</template>

<script setup>
import { newFolder, renameFolder } from '@/api/folder'
import { folderColors } from '@/libs/config'
import { reactive, watch, defineProps, defineEmits, getCurrentInstance, defineModel } from 'vue'
import ConfirmModal from '@/components/common/confirm-modal.vue'

const _this = getCurrentInstance().appContext.config.globalProperties

// 弹窗是否显示
const model = defineModel()

const state = reactive({
  loading: false,
  title: '',
  targetName: '',
  isCreate: true
})

// 定义props
const props = defineProps({
  fileInfo: Object,
  isShow: Boolean,
  folderLength: Number
})
const emit = defineEmits(['on-success', 'update:modelValue'])

watch(
  () => model.value,
  (val) => {
    if (val) {
      state.isCreate = props.fileInfo?.folderCode == null
      state.title = state.isCreate ? _this.$t('file.dialog.creatTitle') : _this.$t('file.dialog.renameTitle')
      state.targetName = props.fileInfo?.folderName || ''
    }
  })
/**
* @description: 提交
* @author: liuhua.chen
* @Date: 2024-11-05 08:42:10
*/
const onSubmit = () => {
  const sort = props.folderLength + 1
  const color = folderColors[sort % folderColors.length]

  const fun = state.isCreate ? newFolder({ sort: props.folderLength + 1, targetName: state.targetName, color }) : renameFolder({ targetName: state.targetName, folderCode: props.fileInfo.folderCode })

  state.loading = true

  fun.then(() => {
    _this.$Message.success(_this.$t('common.success'))
    emit('update:modelValue', false)
    emit('on-success')
  }).finally(() => { state.loading = false })
}

</script>
<style lang="less" scoped>
.title-wrap {
  // width: 13rem;
  margin: 5px 0;
  display: flex;
  justify-content: baseline;

  .span-text {
    color: red;
    font-size: 20px;
    margin-right: 3px;
  }
}
</style>
